import { setGameID } from 'src/slices/game.slice';
import {
    setDisableRemote,
    setPokiAnalytics,
    setUploadHistory,
} from 'src/slices/inspector.slice';
import { setStepsDisabledStatus, updateCurrentOpenModule } from 'src/slices/qamodules.slice';
import { setPersistedState } from 'src/utils/persist';

const statePersistingActions = {
    [setDisableRemote.type]: {
        state: 'inspector',
        property: 'disableRemote',
    },
    [setPokiAnalytics.type]: {
        state: 'inspector',
        property: 'pokiAnalytics',
        id: 'poki_analytics',
        acceptedValues: [true, false],
    },
    [setGameID.type]: {
        state: 'game',
        property: 'gameID',
    },
    [setUploadHistory.type]: {
        state: 'inspector',
        property: 'uploadHistory',
        id: 'poki_upload_history',
        standalone: true,
    },
    [updateCurrentOpenModule.type]: {
        state: 'qaModules',
        property: 'currentOpenModule',
    },
    [setStepsDisabledStatus.type]: {
        state: 'qaModules',
        property: 'stepsDisabledStatus',
    },
};

const stateSyncMiddleware = (store) => (next) => (action) => {
    if (statePersistingActions[action.type]) {
        const {
            id,
            property,
            acceptedValues,
            standalone,
        } = statePersistingActions[action.type];

        const stateToPersist = standalone ? action.payload : action.payload[property];

        try {
            const { id: gameID } = store.getState().game.entity;

            // Check if acceptedValues is not defined or if persistedState is in acceptedValues
            if (!acceptedValues || (acceptedValues && acceptedValues.includes(stateToPersist))) {
                setPersistedState({ state: standalone ? stateToPersist : { [property]: stateToPersist }, id: standalone ? id : gameID });
            }
        } catch (e) {
            console.error('Failed to persist state', e);
        }
    }

    // Continue to the next middleware or reducer
    return next(action);
};

export default stateSyncMiddleware;
