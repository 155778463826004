import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SVGIcon from 'src/components/ui/SVGIcon';
import StatusBadge from 'src/components/ui/StatusBadge';
import Tooltip from 'src/components/ui/Tooltip';

const DropdownWrapper = styled.div``;

const DropdownTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 8px 16px;

    &:hover {
        cursor: pointer;
        background: var(--grey-8);
    }
`;

const DropdownTitle = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: var(--grey-3);
`;

const DropDownItems = styled.ul``;

const DropdownItem = styled.li`
    font-size: 12px;
    margin: 4px 0;
`;

const DropdownIcon = styled(SVGIcon)`
    width: 7px;
    height: 12px;

    color: var(--grey-5);
    transform: rotate(180deg);

    ${(props) => props.$isOpen && `
        transform: rotate(-90deg);
    `}
`;

const TooltipContentWrapper = styled.span`
    margin-left: auto;
`;

function Dropdown({ items, selectedItem, options }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <DropdownWrapper>
            <DropdownTitleWrapper onClick={toggleDropdown}>
                <DropdownIcon icon="chevronleft" $isOpen={isOpen} />
                <DropdownTitle>
                    {selectedItem}
                </DropdownTitle>
                {options.length && (
                    <StatusBadge title={items.length > 0 && items.length} />
                )}
                {options.tooltip && (
                    <Tooltip content={options.tooltip.content}>
                        <TooltipContentWrapper>
                            {options.tooltip.title}
                        </TooltipContentWrapper>
                    </Tooltip>
                )}
            </DropdownTitleWrapper>
            {isOpen && (
                <DropDownItems>
                    {items.map((item) => (
                        <DropdownItem>
                            {item}
                        </DropdownItem>
                    ))}
                </DropDownItems>
            )}
        </DropdownWrapper>
    );
}

export default Dropdown;

Dropdown.propTypes = {
    items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])).isRequired,
    selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    options: PropTypes.shape({
        icon: PropTypes.string,
        length: PropTypes.number,
        tooltip: PropTypes.shape({
            title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
            content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        }),
    }),
};

Dropdown.defaultProps = {
    selectedItem: '',
    options: {},
};
