import styled from 'styled-components';

import GameDetails from 'src/components/ui/GameDetails';

const DetailsModuleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export default function DetailsModule() {
    return (
        <DetailsModuleWrapper>
            <GameDetails />
        </DetailsModuleWrapper>
    );
}
