import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
    events: [],
    inspectorEvents: [],
};

const gameEventsSlice = createSlice({
    name: 'gameEvents',
    initialState,
    reducers: {
        setInitialState: (state) => {
            state.events = initialState.events;
        },
        setGameEvent: (state, action) => {
            state.events.push(action.payload);
        },
        setInspectorEvent: (state, action) => {
            state.inspectorEvents.push(action.payload);
        },
        setGameEvents: (state, action) => {
            state.events = action.payload;
        },
        clearGameEvents: (state) => {
            state.events = initialState.events;
        },
    },
});

export const {
    setInitialState,
    setGameEvent,
    setInspectorEvent,
    setGameEvents,
    clearGameEvents,
} = gameEventsSlice.actions;

export const selectGameEvents = createSelector(
    (state) => state.gameEvents,
    (gameEvents) => gameEvents.events,
);

export const selectInspectorEvents = createSelector(
    (state) => state.gameEvents,
    (gameEvents) => gameEvents.inspectorEvents,
);

export default gameEventsSlice.reducer;
