import { Network } from '@poki/netlib';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { selectGame, setGame, setRenderGame } from 'src/slices/game.slice';
import { setGameEvent } from 'src/slices/gameEvents.slice';
import { NETWORK_MESSAGE_TYPE_GAME_EVENT, NETWORK_MESSAGE_TYPE_CONSOLE } from 'src/vars';

let network = null;

export function getNetlibNetwork() {
    if (!network) {
        // DefinePlugin will replace INSPECTOR_UUID with the actual value
        // eslint-disable-next-line no-undef
        network = new Network(INSPECTOR_UUID);
    }
    return network;
}

// generate a shareable url for the remote inspector instance to connect to
function generateShareURL(lobby) {
    const url = new URL(window.location.href);
    // remove search params first
    url.search = '';
    // add the lobby and remote params
    url.searchParams.set('lobby', lobby);
    url.searchParams.set('remote', 'true'); // check App.js for branch logic
    return url.toString();
}

const initialState = {
    peers: [], // store the number of peers connected to the network
    isReady: false,
    error: null,
    remoteURL: null,
};

export const netlibSlice = createSlice({
    name: 'netlib',
    initialState,
    reducers: {
        setInitialState: (state) => {
            state.peers = initialState.peers;
            state.isReady = initialState.isReady;
            state.error = initialState.error;
            state.remoteURL = initialState.remoteURL;
        },
        setNetlibError: (state, action) => {
            state.error = action.payload.error;
        },
        setNetlibIsReady: (state, action) => {
            state.isReady = action.payload.isReady;
        },
        setNetlibRemoteURL: (state, action) => {
            state.remoteURL = action.payload.remoteURL;
        },
        setNetlibPeers: (state, action) => {
            state.peers = action.payload.peers;
        },
    },
});

export const selectIsNetlibReady = (state) => state.netlib.isReady;
export const selectNetlibError = (state) => state.netlib.error;
export const selectNetlibPeers = (state) => state.netlib.peers;
export const selectNetlibRemoteURL = (state) => state.netlib.remoteURL;

export const {
    setInitialState,
    setNetlibError,
    setNetlibIsReady,
    setNetlibPeers,
    setNetlibRemoteURL,
} = netlibSlice.actions;

// startNetlibHost is used to create a netlib lobby on a host inspector
// it will also listen to incoming message and dispatch the appropriate actions (e.g gameEvents)
// the host inspector will also send a game url to the remote inspector to connect to
export const startNetlibHost = createAsyncThunk(
    'netlib/startNetlibHost',
    async (_, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();

            const { url: gameURL } = selectGame(state);

            if (!gameURL) {
                throw new Error('Error: Game URL is missing');
            }

            await getNetlibNetwork(); // init the netlib network

            network.on('lobby', (lobby) => {
                console.info(`🌍 Netlib[host] lobby was created with code: ${lobby}`);
                const remoteURL = generateShareURL(lobby);
                dispatch(setNetlibRemoteURL({ remoteURL }));
            });

            network.on('ready', () => {
                network.create();
                dispatch(setNetlibIsReady({ isReady: true }));
            });

            network.on('message', (peer, channel, data) => {
                const message = JSON.parse(data);

                if (message?.type === NETWORK_MESSAGE_TYPE_GAME_EVENT) {
                    dispatch(setGameEvent(message.content));
                } else if (message?.type === NETWORK_MESSAGE_TYPE_CONSOLE) {
                    const args = JSON.parse(message.data.args);
                    console[message.data.level]('%cREMOTE GAME:%c', 'font-weight: bold', '', ...args); // eslint-disable-line no-console
                }
            });

            network.on('connected', (peer) => {
                const { id } = peer;
                const peers = selectNetlibPeers(getState());

                // if the peer already exists, do not add it again (this can happen when the netlib reconnects)
                if (peers.includes(id)) {
                    return;
                }

                const nextPeers = [...peers, id];

                if (nextPeers.length === 1) {
                    // send the game url to the remote inspector so it can connect to the lobby
                    const message = {
                        type: 'gameURL',
                        value: gameURL,
                    };

                    network.broadcast('reliable', JSON.stringify(message));
                }

                dispatch(setNetlibPeers({ peers: nextPeers }));
            });

            // when a peer disconnects, remove it from the peers list
            network.on('disconnected', (peer) => {
                const { id } = peer;
                const peers = selectNetlibPeers(getState());
                const nextPeers = peers.filter((peerID) => peerID !== id);
                dispatch(setNetlibPeers({ peers: nextPeers }));
            });

            return true;
        } catch (error) {
            const { message = 'Unknown error' } = error;
            dispatch(setNetlibError({ error: message }));
            return rejectWithValue(message);
        }
    },
);

export const stopNetlibHost = createAsyncThunk(
    'netlib/stopNetlibHost',
    async (_, { dispatch }) => {
        // close the network
        if (network) {
            console.info('🌍 Netlib[host] is closing');

            const message = {
                type: 'inspector',
                value: 'disconnect',
            };

            // let everyone know that the host is disconnecting
            network.broadcast('reliable', JSON.stringify(message));

            network.close();
            network = null;
        }

        // reset the state
        dispatch(setInitialState());
    },
);

// startNetlibRemote is used to connect to a netlib lobby on a remote inspector
export const startNetlibRemote = createAsyncThunk(
    'netlib/startNetlibRemote',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const searchParams = new URLSearchParams(window.location.search);
            const lobby = searchParams.get('lobby'); // lobby for netlib

            if (!lobby) {
                throw new Error('Lobby is missing');
            }

            await getNetlibNetwork();

            network.on('ready', () => {
                network.join(lobby);
                console.info('🌏 Netlib[remote] is ready and joined a lobby');
                dispatch(setNetlibIsReady({ isReady: true }));
            });

            // set a timeout to throw an error if the game url is not received
            // it shouldn't take more than 10 seconds to receive the game url
            // if it does, it means something went wrong
            const messageTimeout = setTimeout(() => {
                dispatch(setNetlibError({ error: 'Game URL was not received' }));
            }, 10000);

            // wait for a game url to be received
            // if it is received, set the game url and render the game
            network.on('message', (peer, channel, data) => {
                const message = JSON.parse(data);
                const { type, value } = message;

                // service messages (can be extended)
                if (type === 'inspector' && value === 'disconnect') {
                    dispatch(setNetlibError({ error: 'Host disconnected' }));
                    return;
                }

                // game url is received and the game should be rendered
                if (type === 'gameURL') {
                    clearTimeout(messageTimeout); // clear the timeout since the game url was received

                    if (!value) {
                        dispatch(setNetlibError({ error: 'Game URL is missing' }));
                        return;
                    }

                    // set the game
                    dispatch(setGame({
                        id: 0,
                        iframeUrl: value,
                    }));

                    // render the game
                    dispatch(setRenderGame({ toggle: true }));
                }
            });

            return true;
        } catch (error) {
            const { message = 'Unknown error' } = error;
            dispatch(setNetlibError({ error: message }));
            return rejectWithValue(message);
        }
    },
);

export default netlibSlice.reducer;
