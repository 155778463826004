import styled from 'styled-components';

import MarketingFooter from 'src/components/ui/MarketingFooter';
import Button from 'src/components/ui/Button';

const MobileMessageWrapper = styled.div`
    background: #fff;
    height: 100vh;
`;

const MobileMessageContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 32px;
    padding: 0 16px;
    width: 100%;

    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const MobileMessageLogo = styled.img`
    width: 180px;
    margin-bottom: 16px;
`;

const MobileMessageTextWrapper = styled.div`
    font-size: 16px;
    font-weight: 700;
    text-align: center;
`;

const MobileMessageActionButton = styled(Button)`
    background: var(--poki-blue);
    border-color: var(--poki-blue);
    color: #fff;
`;

const MobileMessageCoinBackground = styled.div`
    position: absolute;
    top: 0;
    left: 0;

    background: url('/assets/coins-background.svg') no-repeat top left / cover;
    width: 100%;
    height: calc(100% - 150px);
`;

function MobileMessage() {
    const handleClick = () => {
        window.open('https://developers.poki.com', '_blank');
    };

    return (
        <MobileMessageWrapper>
            <MobileMessageCoinBackground />
            <MobileMessageContent>
                <MobileMessageLogo src="/assets/logo.svg" alt="Poki for Developers - Beta" />
                <MobileMessageTextWrapper>
                    <p>
                        Test, debug, and optimize your games for web with the Poki Inspector Tool.
                        Designed for desktop, head to Poki for Developers to get started!
                    </p>
                </MobileMessageTextWrapper>
                <MobileMessageTextWrapper>
                    <MobileMessageActionButton onClick={handleClick}>
                        Go to developers.poki.com
                    </MobileMessageActionButton>
                </MobileMessageTextWrapper>
            </MobileMessageContent>
            <MarketingFooter />
        </MobileMessageWrapper>
    );
}

export default MobileMessage;
