import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import SVGIcon from 'src/components/ui/SVGIcon';
import Dropdown from 'src/components/ui/Dropdown';
import StatusBadge from 'src/components/ui/StatusBadge';

import { SCREEN_SIZES, STATUS_BADGE, WARNINGS } from 'src/vars';
import { selectGameWarnings } from 'src/slices/game.slice';

const WarningsModuleWrapper = styled.div`
    height: 100%;
    background: #fff;
    overflow-y: auto;
    border-radius: 8px;
    padding-bottom: 60px;

    @media (min-width: ${SCREEN_SIZES.LARGE}px) {
        max-height: 220px;
        padding-bottom: 0;
    }
`;

const WarningsModuleTitle = styled.h2`
    display: none;
    width: 100%;

    position: sticky;
    top: 0;
    background: #fff;

    margin: 0;
    padding: 13px 16px 12px;

    font-size: 21px;
    border-bottom: 2px solid var(--grey-8);
    z-index: 1;

    ${(props) => !props.$hasWarnings && `
        color: var(--grey-5);
    `}

    @media (min-width: ${SCREEN_SIZES.LARGE}px) {
        display: flex;
        align-items: center;
        gap: 8px;
    }
`;

const WarningsModuleNoMatches = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 32px 16px 16px;
    font-size: 14px;
    color: var(--grey-5);
`;

const WarningsModuleNoMatchesTitle = styled.h3`
    font-size: 14px;
`;

const WarningModulesContentWrapper = styled.div`
    padding: 0;
`;

const WarningsModuleTooltipWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const WarningsModuleTooltipTitle = styled.span`
    font-size: 14px;
    font-weight: 600;
`;

const WarningsModuleIcon = styled(SVGIcon)`
    display: inline-block;
    vertical-align: middle;

    width: 20px;
    height: 20px;
    margin: -2px 0 0 4px;

    color: var(--grey-5);
`;

const WarningsModuleCatIcon = styled(WarningsModuleIcon)`
    width: 64px;
    height: 64px;
`;

const WarningsModuleItemDescription = styled.p`
    font-weight: 400;
`;

const WarningsModuleLink = styled.a`
    color: var(--poki-blue);
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    &:visited {
        color: var(--poki-blue);
    }
`;

const WarningsModuleImagePercentage = styled.span`
    color: var(--green-1);
`;

const getPercentageOptimized = (image) => {
    const { original_size: originalSize, optimized_size: optimizedSize } = image;

    const percentage = ((originalSize - optimizedSize) / originalSize) * 100;

    return percentage.toFixed(2);
};

const getWarning = (warning) => {
    let mappedWarning = {};

    if (warning.key === WARNINGS.IMAGE_SCANNER) {
        const images = warning.images.map((image) => ({
            name: image.name,
            percentage: getPercentageOptimized(image),
        })).sort((a, b) => b.percentage - a.percentage);

        mappedWarning = {
            key: warning.key,
            title: 'Image optimization',
            description: (
                <WarningsModuleItemDescription>
                    Our system has detected that some of the images in this game could be optimized and/or compressed without loss of quality.
                    This could greatly improve the conversion to play of your game. Checkout
                    {' '}
                    <WarningsModuleLink href="https://tinypng.com" target="_blank" rel="noreferrer">https://tinypng.com</WarningsModuleLink>
                    {' '}
                    or
                    {' '}
                    <WarningsModuleLink href="https://squoosh.app" target="_blank" rel="noreferrer">https://squoosh.app</WarningsModuleLink>
                    {' '}
                    to optimize your images.
                </WarningsModuleItemDescription>
            ),
            items: images
                .filter((i) => i.percentage >= 1)
                .map(({ percentage, name }) => (
                    <>
                        <WarningsModuleImagePercentage>
                            {percentage}
                            %
                        </WarningsModuleImagePercentage>
                        {' - '}
                        {name}
                    </>
                )),
            order: 1,
        };
    }

    if (warning.key === WARNINGS.CSP_REPORTS) {
        mappedWarning = {
            key: warning.key,
            title: 'External resource loading',
            description: (
                <WarningsModuleItemDescription>
                    Your game appears to be utilizing resources from external (non-Poki) domains.
                    This practice is typically not allowed and may result in suboptimal performance.
                    Please ensure all resources are inlined within the uploaded build, or acquire authorization from Poki for backend usage.
                </WarningsModuleItemDescription>
            ),
            items: warning.hosts,
            order: 0,
        };
    }

    if (warning.key === WARNINGS.BAD_BEHAVIOR) {
        mappedWarning = {
            key: warning.key,
            title: 'Unexpected behavior detected',
            description: (
                <WarningsModuleItemDescription>
                    The game has exhibited behavior that may disrupt the expected functionality of the SDK.
                </WarningsModuleItemDescription>
            ),
            items: warning.items,
            order: 2,
        };
    }

    return mappedWarning;
};

export default function WarningsModule() {
    const warnings = useSelector(selectGameWarnings);

    const hasWarnings = warnings?.length > 0;

    const sortedWarnings = useMemo(() => {
        if (!hasWarnings) {
            return [];
        }

        return warnings.map((warning) => getWarning(warning)).sort((a, b) => a.order - b.order);
    }, [warnings, hasWarnings]);

    return (
        <WarningsModuleWrapper>
            <WarningsModuleTitle $hasWarnings={hasWarnings}>
                Warnings
                {' '}
                {hasWarnings && <StatusBadge variant={STATUS_BADGE.WARNING} title={warnings.length} />}
            </WarningsModuleTitle>
            {hasWarnings ? (
                sortedWarnings.map(({
                    items, title, description, key,
                }) => (
                    <WarningModulesContentWrapper key={key}>
                        <Dropdown
                            items={items}
                            selectedItem={(
                                <WarningsModuleTooltipWrapper>
                                    <WarningsModuleTooltipTitle>{title}</WarningsModuleTooltipTitle>
                                </WarningsModuleTooltipWrapper>
                            )}
                            options={{
                                length: items.length,
                                tooltip: {
                                    title: (<WarningsModuleIcon icon="question" />),
                                    content: description,
                                },
                            }}
                        />
                    </WarningModulesContentWrapper>
                ))
            ) : (
                <WarningsModuleNoMatches>
                    <WarningsModuleNoMatchesTitle>
                        No issues detected (yet)
                    </WarningsModuleNoMatchesTitle>
                    {' '}
                    so here’s a cat instead
                    <WarningsModuleCatIcon icon="cat" />
                </WarningsModuleNoMatches>
            )}
        </WarningsModuleWrapper>
    );
}
