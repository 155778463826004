import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import SVGIcon from 'src/components/ui/SVGIcon';

import { STEP_STATUS } from 'src/assets/qamodules/default';

const PassToggleIcon = styled(SVGIcon)`
    color: var(--passToggleIconColor, var(--grey-5));

    ${(props) => {
        switch (props.icon) {
        case 'check':
            return `
                --passToggleIconColor: var(--poki-blue);
                width: 15px;
                height: 11px;
            `;
        case 'cross':
            return `
                --passToggleIconColor: var(--poki-blue);
                width: 11px;
                height: 11px;
            `;
        default: return '';
        }
    }}
`;

const PassToggleButtonStyles = css`
    width: 32px;
    height: 32px;
    background: var(--grey-7);
    border: none;
    cursor: pointer;

    ${PassToggleIcon} {
        --passToggleIconColor: var(--poki-blue);
        margin: 0 auto;
    }
`;

const PassToggleButtonStylesChecked = css`
    background: var(--poki-hover-1);

    &:hover {
        background: var(--poki-hover-1);
    }

    ${PassToggleIcon} {
        --passToggleIconColor: #fff;
    }
`;

const PassToggleButtonWrapper = styled.button`
    ${PassToggleButtonStyles}

    &:hover {
        background: var(--grey-8);
    }

    ${({ $position, $status }) => $position === 'left' && `
        border-radius: 4px 0 0 4px;

        ${$status === STEP_STATUS.FAILED && `
        ${PassToggleButtonStylesChecked}
        `}
    `}

    ${({ $position, $status }) => $position === 'right' && `
        border-radius: 0 4px 4px 0;

        ${$status === STEP_STATUS.PASSED && `
        ${PassToggleButtonStylesChecked}
        `}
    `}
`;

const PassToggleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 2px;

    ${({ $isDisabled, $status }) => ($isDisabled || ($isDisabled && $status === STEP_STATUS.NOT_STARTED)) && `
        filter: grayscale(100%);
        pointer-events: none;
    `}
`;

export default function PassToggle({
    status,
    disabled, // most of the time it means that it's automatically passed via rxjs
    callbackPassed,
    callbackFailed,
    callbackClear,
}) {
    const handleFailedClick = () => {
        callbackFailed();

        if (status === STEP_STATUS.FAILED) {
            callbackClear();
        }
    };

    const handlePassedClick = () => {
        callbackPassed();

        if (status === STEP_STATUS.PASSED) {
            callbackClear();
        }
    };

    return (
        <PassToggleWrapper $status={status} $isDisabled={disabled}>
            <PassToggleButtonWrapper $status={status} $position="left" onClick={handleFailedClick}>
                <PassToggleIcon icon="cross" />
            </PassToggleButtonWrapper>
            <PassToggleButtonWrapper $status={status} $position="right" onClick={handlePassedClick}>
                <PassToggleIcon icon="check" />
            </PassToggleButtonWrapper>
        </PassToggleWrapper>
    );
}

PassToggle.propTypes = {
    status: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    callbackPassed: PropTypes.func,
    callbackFailed: PropTypes.func,
    callbackClear: PropTypes.func,
};

PassToggle.defaultProps = {
    disabled: false,
    callbackPassed: () => {},
    callbackFailed: () => {},
    callbackClear: () => {},
};
