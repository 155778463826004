export const SCREEN_SIZES = {
    SMALL: 720,
    MEDIUM: 1024,
    LARGE: 1330,
    XLARGE: 1920,
};

export const VIEW_MODES = {
    DESKTOP: 'desktop',
    MOBILE: 'mobile',
    REMOTE: 'remote',
    LOCAL: 'local',
};

export const ORIENTATION = {
    LANDSCAPE: 'landscape',
    PORTRAIT: 'portrait',
};

export const STATUS_BADGE = {
    WARNING: 'warning',
    ERROR: 'error',
    SUCCESS: 'success',
};

export const SDK_EVENTS = {
    SDK_STATUS_INITIALIZED: 'pokiTrackingSdkStatusInitialized',
    SDK_STATUS_FAILED: 'pokiTrackingSdkStatusFailed',
    GAME_LOADING_FINISHED: 'pokiTrackingScreenGameLoadingFinished',
    GAMEPLAY_START: 'pokiTrackingScreenGameplayStart',
    GAMEPLAY_STOP: 'pokiTrackingScreenGameplayStop',
    COMMERCIAL_BREAKS: 'pokiTrackingScreenCommercialBreak',
    REWARDED_BREAKS: 'pokiTrackingScreenRewardedBreak',
};

export const STEPS_DISABLED_STATUS = {
    'sdk-basics-init-sdk': true,
    'sdk-basics-game-loading-finished': true,
    'sdk-basics-gameplay-start': true,
    'sdk-basics-gameplay-stop': true,
    'ad-implementation-commercial-breaks': true,
    'ad-implementation-rewarded-breaks': true,
};

export const WARNINGS = {
    IMAGE_SCANNER: 'image-scanner',
    CSP_REPORTS: 'csp-reports',
    BAD_BEHAVIOR: 'bad-behavior',
};

export const TIME_FORMAT_PLACEHOLDER = '--:--:--';
export const SIZE_FORMAT_PLACEHOLDER = '-- MB';

export const MOBILE_DEVICES = [
    {
        id: 'samsung-galaxy-j5',
        label: 'Samsung Galaxy J5 (360 x 640)',
        device: 'mobile',
        value: ['360px', '640px'],
        orientation: ORIENTATION.PORTRAIT,
    },
    {
        id: 'samsung-galaxy-s20',
        label: 'Samsung Galaxy S20 (360 x 800)',
        device: 'mobile',
        value: ['360px', '800px'],
        orientation: ORIENTATION.PORTRAIT,
    },
    {
        id: 'iphone-xr',
        label: 'iPhone XR (414 x 896)',
        device: 'mobile',
        value: ['414px', '896px'],
        orientation: ORIENTATION.PORTRAIT,
    },
    {
        id: 'ipad-mini',
        label: 'iPad Mini (768 x 1024)',
        device: 'mobile',
        value: ['768px', '1024px'],
        orientation: ORIENTATION.PORTRAIT,
    },
    {
        id: 'ipad-10.2',
        label: 'iPad 10.2" (810 x 1080)',
        device: 'mobile',
        value: ['810px', '1080px'],
        orientation: ORIENTATION.PORTRAIT,
    },
];

export const SCALING_SIZES = [
    {
        label: 'Max Size',
        device: 'desktop',
        value: ['100%', '100%'],
    },
    {
        label: '1031 x 580',
        device: 'desktop',
        value: ['1031px', '580px'],
    },
    {
        label: '836 x 470',
        device: 'desktop',
        value: ['836px', '470px'],
    },
    {
        label: '640 x 360',
        device: 'desktop',
        value: ['640px', '360px'],
    },
    {
        values: MOBILE_DEVICES,
    },
];

export const NETWORK_MESSAGE_TYPE_GAME_EVENT = 'gameEvent';
export const NETWORK_MESSAGE_TYPE_CONSOLE = 'console';
