import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';

import stateSyncMiddleware from 'src/utils/stateSyncMiddleware';

import appReducer from 'src/slices/app.slice';
import inspectorReducer from 'src/slices/inspector.slice';
import gameReducer from 'src/slices/game.slice';
import gameEventsReducer from 'src/slices/gameEvents.slice';
import qaModulesReducer from 'src/slices/qamodules.slice';
import modalReducer from 'src/slices/modal.slice';
import uploadReducer from 'src/slices/upload.slice';
import netlibReducer from 'src/slices/netlib.slice';
import notificationReducer from 'src/slices/notification.slice';

import rootEpic from 'src/epics/rootEpic';

const epicMiddleware = createEpicMiddleware();

const rootReducer = combineReducers({
    app: appReducer,
    inspector: inspectorReducer,
    gameEvents: gameEventsReducer,
    qaModules: qaModulesReducer,
    game: gameReducer,
    modal: modalReducer,
    upload: uploadReducer,
    netlib: netlibReducer,
    notification: notificationReducer,
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([epicMiddleware, stateSyncMiddleware]),
});

if (typeof window.requestIdleCallback === 'undefined') {
    epicMiddleware.run(rootEpic);
} else {
    window.requestIdleCallback(() => {
        epicMiddleware.run(rootEpic);
    });
}

export default store;
