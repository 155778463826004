import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    notifications: [],
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        openNotification: (state, action) => {
            state.notifications.push(action.payload);
        },
        closeNotification: (state, action) => {
            state.notifications = state.notifications?.filter((notification) => notification.id !== action.payload);
        },
        clearNotifications: (state) => {
            state.notifications = [];
        },
    },
});

export const { openNotification, closeNotification, clearNotifications } = notificationSlice.actions;

export const selectNotifications = (state) => state.notification.notifications;

export default notificationSlice.reducer;
