// convert timestamp to [hh:mm:ss format]
export function convertTimestamp(timestamp) {
    return new Date(timestamp).toLocaleTimeString('en-US', {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });
}

// convert seconds to [hh:mm:ss format]
export function secondsToHHMMSS(seconds) {
    const secNum = parseInt(seconds, 10);
    let hours = Math.floor(secNum / 3600);
    let minutes = Math.floor((secNum - hours * 3600) / 60);
    let secs = secNum - hours * 3600 - minutes * 60;

    if (hours < 10) {
        hours = `0${hours}`;
    }
    if (minutes < 10) {
        minutes = `0${minutes}`;
    }
    if (secs < 10) {
        secs = `0${secs}`;
    }
    return `${hours}:${minutes}:${secs}`;
}
