import PropTypes from 'prop-types';
import JSZip from 'jszip';
import { useRef, useState, useId } from 'react';
import styled, { css } from 'styled-components';

import SVGIcon from 'src/components/ui/SVGIcon';
import addEntryToZip from 'src/utils/zip';

const DragAndDropWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    height: 100%;

    border-radius: 8px;
    border: 2px dashed var(--grey-5);

    ${({ $isActive }) => $isActive && css`
        border-color: (--poki-blue);
        background-color: var(--grey-8);
    `}
`;

const DragAndDropFormInput = styled.input`
    display: none;
`;

const DragAndDropInputLabel = styled.label`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    gap: 32px;
`;

const DragAndDropTitle = styled.h3`
    margin: 0;
    color: var(--grey-3);
`;

const DragAndDropUploadButton = styled.button`
    background: none;
    border: none;

    color: var(--poki-blue);

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;

const DragAndDropIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    width: 104px;
    height: 104px;
    border-radius: 10px;
    background: ${({ $isActive }) => ($isActive ? '#fff' : 'var(--grey-7)')};
`;

const DragAndDropSVGIcon = styled(SVGIcon)`
    width: 53px;
    height: 48px;
    color: var(--poki-blue);
`;

const DragAndDropEvents = styled.div`
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
`;

const generateZipFile = async ({ file, topLevelFolder }) => {
    let fileName = file.name;
    const zip = new JSZip();

    try {
        if (file instanceof FileList) {
            const promises = [];
            [fileName] = file[0].webkitRelativePath.split('/');

            for (let i = 0; i < file.length; i += 1) {
                promises.push(file[i].arrayBuffer().then((fileData) => {
                    zip.file(file[i].webkitRelativePath, fileData);
                }));
            }

            await Promise.all(promises);
        } else {
            // Recursively add each file and subfolder to the zip file
            await addEntryToZip(zip, topLevelFolder, '');
        }

        const blob = await zip.generateAsync({ type: 'blob' });

        return new File([blob], `${fileName}.zip`, { type: 'application/zip' });
    } catch (err) {
        console.error('Error while creating zip file', err);
        throw err;
    }
};

export default function DragAndDrop({ onUpload }) {
    const inputRef = useRef(null);
    const inputId = useId(); // generate id for input to link it to label

    const [isDragActive, setIsDragActive] = useState(false);

    const handleFileUpload = ({ gameFile }) => {
        onUpload({ gameFile });
    };

    const handleOnClickUpload = () => {
        inputRef.current.click(); // trigger file input
    };

    const handleInputFileChange = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e?.target?.files?.[0]) {
            const fileList = e.target.files;
            const topLevelFolder = fileList[0].webkitRelativePath.split('/')[0];

            if (!fileList) return null;

            const zippedFile = await generateZipFile(
                { file: e.target.files, topLevelFolder },
            );

            handleFileUpload({ gameFile: zippedFile });
        }

        return null;
    };

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.type === 'dragover' || e.type === 'dragenter') {
            setIsDragActive(true);
        } else if (e.type === 'dragleave') {
            setIsDragActive(false);
        }
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        setIsDragActive(false);

        const file = e.dataTransfer.files[0];
        const topLevelFolder = e.dataTransfer.items[0].webkitGetAsEntry();

        if (!file) {
            return null;
        }

        if (topLevelFolder.isDirectory) {
            // If the user drags a folder, we need to create a zip file
            const zippedFile = await generateZipFile({ file, topLevelFolder });

            handleFileUpload({ gameFile: zippedFile });
        }

        return handleFileUpload({ gameFile: file });
    };

    return (
        <DragAndDropWrapper $isActive={isDragActive} onDragEnter={handleDrag}>
            <>
                <DragAndDropFormInput ref={inputRef} onChange={handleInputFileChange} type="file" webkitdirectory="" directory="" multiple name={inputId} />
                <DragAndDropInputLabel htmlFor={inputId}>
                    <DragAndDropIconWrapper
                        onClick={handleOnClickUpload}
                        $isActive={isDragActive}
                    >
                        <DragAndDropSVGIcon icon="gamepadfolder" />
                    </DragAndDropIconWrapper>
                    <DragAndDropTitle>
                        Drag and drop here or
                        <DragAndDropUploadButton onClick={handleOnClickUpload}>
                            browse to choose a folder
                        </DragAndDropUploadButton>
                    </DragAndDropTitle>
                </DragAndDropInputLabel>
            </>
            { isDragActive && (
                <DragAndDropEvents
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                />
            )}
        </DragAndDropWrapper>
    );
}

DragAndDrop.propTypes = {
    onUpload: PropTypes.func.isRequired,
};
