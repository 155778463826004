import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Modal from 'src/components/ui/Modal';
import Notifications from 'src/components/ui/Notifications';
import CenterBlockLayout from 'src/components/layout/CenterBlockLayout';
import LeftBlockLayout from 'src/components/layout/LeftBlockLayout';
import RightBlockLayout from 'src/components/layout/RightBlockLayout';

import { selectGame } from 'src/slices/game.slice';
import { selectShowModules, selectUploadHistory, setUploadHistory } from 'src/slices/inspector.slice';
import { sendEvent } from 'src/utils/tracking';

const LocalTestingWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    gap: 24px;

    height: calc(100vh - 104px);
    margin: 0 auto;
    padding: 0 24px;
`;

export default function LocalTesting() {
    const dispatch = useDispatch();

    const showModules = useSelector(selectShowModules);
    const uploadHistory = useSelector(selectUploadHistory);
    const { id: gameID, gameTitle, originalFilename } = useSelector(selectGame);

    useEffect(() => {
        if (!gameID) {
            return;
        }

        dispatch(setUploadHistory({
            [gameID]: {
                id: gameID,
                gameTitle,
                originalFilename,
                timestamp: Date.now(),
            },
        }));
    }, [dispatch, gameID, gameTitle, originalFilename]);

    // if game id is in the url, load it automatically
    useEffect(() => {
        sendEvent('app-load');
    }, []);

    return (
        <LocalTestingWrapper>
            <LeftBlockLayout showModules={showModules} uploadHistory={uploadHistory} />
            <CenterBlockLayout showModules={showModules} />
            <RightBlockLayout showModules={showModules} />
            <Modal />
            <Notifications />
        </LocalTestingWrapper>
    );
}
