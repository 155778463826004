import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpen: false,
    isConfirmed: false,
    isDeclined: false,
    content: {
        title: '',
        text: '',
        actions: [],
    },
};

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        open: (state, action) => {
            state.isOpen = true;
            state.content = action.payload.content;
        },
        close: (state) => {
            // reset the modal state to the initial state
            state.isOpen = false;
            state.isConfirmed = false;
            state.isDeclined = false;
            state.content = initialState.content;
        },
        setConfirmed: (state) => {
            state.isConfirmed = true;
            state.isOpen = false;
        },
        setDeclined: (state) => {
            state.isDeclined = true;
            state.isOpen = false;
        },
    },
});

export const {
    open,
    close,
    setConfirmed,
    setDeclined,
} = modalSlice.actions;

export const openModal = createAsyncThunk(
    'modal/openModal',
    async (payload, { getState, dispatch }) => {
        const { content } = payload;
        const { title, text, actions } = content;

        dispatch(
            open({
                content: {
                    title,
                    text,
                    actions,
                },
            }),
        );

        return new Promise((resolve) => {
            const unsubscribe = (value) => {
                dispatch(close());
                resolve(value);
            };

            // TOOD is there a better way to do this?
            const unsubscribeInterval = setInterval(() => {
                const state = getState().modal;
                const { isConfirmed, isDeclined } = state;

                if (isConfirmed || isDeclined) {
                    clearInterval(unsubscribeInterval);
                    unsubscribe(isConfirmed ? 'confirmed' : 'declined');
                }
            }, 100);
        });
    },
);

export const selectModal = (state) => state.modal;

export default modalSlice.reducer;
