import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PlaceholderBlock, PlaceholderSVGIcon } from 'src/components/layout/blockLayout.styles';
import GameModule from 'src/components/modules/GameModule';
import DetailsModule from 'src/components/modules/DetailsModule';

export const CenterBlockLayoutWrapper = styled.div`
    display: flex;
    flex: 2 1 0%;
    flex-direction: column;
    gap: 24px;
    order: 2;
`;

function CenterBlockLayout({ showModules }) {
    const renderContent = () => {
        if (showModules) {
            return (
                <DetailsModule />
            );
        }

        return (
            <PlaceholderBlock>
                <PlaceholderSVGIcon icon="gamepad" $width="64px" $height="92px" />
            </PlaceholderBlock>
        );
    };

    return (
        <CenterBlockLayoutWrapper>
            <GameModule />
            {renderContent()}
        </CenterBlockLayoutWrapper>
    );
}

export default CenterBlockLayout;

CenterBlockLayout.propTypes = {
    showModules: PropTypes.bool.isRequired,
};
