function getQueryParams(searchParam) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(searchParam);
}

function getPersistedState({ id = getQueryParams('game') }) {
    if (!id) return {};

    const persistedState = window.localStorage.getItem(id);

    try {
        if (persistedState) {
            return JSON.parse(persistedState);
        }
    } catch (e) {
        console.error('Error parsing persisted state', e);
    }

    return {};
}

function setPersistedState({ state, id = getQueryParams('game') }) {
    if (!id) return;

    window.localStorage.setItem(id, JSON.stringify({
        ...getPersistedState({ id }),
        ...state,
    }));
}

function clearPersistedState({ id = getQueryParams('game') }) {
    if (id) {
        window.localStorage.removeItem(id);
    }
}

export { setPersistedState, getPersistedState, clearPersistedState };
