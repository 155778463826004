import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { selectIsRemoteTestingMode } from 'src/slices/inspector.slice';
import { setIsFullscreen } from 'src/slices/game.slice';
import { sendEvent } from 'src/utils/tracking';

import ActionButton from 'src/components/ui/ActionButton';

const ToolbarWrapper = styled.div`
    padding: 16px;
    border-radius: 8px;
    background-color: #fff;

    justify-self: flex-end;
`;

const ToolbarControls = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 16px;
`;

export default function Toolbar() {
    const dispatch = useDispatch();

    const isRemoteTestingMode = useSelector(selectIsRemoteTestingMode);

    const handleScalingTestsClick = () => {
        dispatch(setIsFullscreen({ toggle: true }));
    };

    const handleRefreshClick = () => {
        window.location.reload();
    };

    const handleEndSessionClick = () => {
        sendEvent('session-end-manually');
        window.location.href = '/';
    };

    return (
        <ToolbarWrapper>
            <ToolbarControls>
                <ActionButton icon="scale" onClickCallback={handleScalingTestsClick} disabled={isRemoteTestingMode}>
                    Scaling Tests
                </ActionButton>
                <ActionButton icon="refresh" onClickCallback={handleRefreshClick}>
                    Restart
                </ActionButton>
                <ActionButton icon="trash" onClickCallback={handleEndSessionClick}>
                    End Session
                </ActionButton>
            </ToolbarControls>
        </ToolbarWrapper>
    );
}
