import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BlockWrapper, PlaceholderBlock, PlaceholderSVGIcon } from 'src/components/layout/blockLayout.styles';
import EventLogModule from 'src/components/modules/EventLogModule';
import WarningsModule from 'src/components/modules/WarningsModule';
import Toolbar from 'src/components/ui/Toolbar';

import { SCREEN_SIZES } from 'src/vars';

const RightBlockLayoutWrapper = styled(BlockWrapper)`
    order: 3;

    @media (max-width: ${SCREEN_SIZES.LARGE}px) {
        display: none;
    }
`;

function RightBlockLayout({ showModules }) {
    const renderContent = () => {
        if (showModules) {
            return (
                <>
                    <EventLogModule />
                    <WarningsModule />
                    <Toolbar />
                </>
            );
        }

        return (
            <PlaceholderBlock>
                <PlaceholderSVGIcon icon="speechbubble" $width="64px" $height="61px" />
            </PlaceholderBlock>
        );
    };

    return (
        <RightBlockLayoutWrapper $shouldHide>
            {renderContent()}
        </RightBlockLayoutWrapper>
    );
}

export default RightBlockLayout;

RightBlockLayout.propTypes = {
    showModules: PropTypes.bool.isRequired,
};
