import styled from 'styled-components';
import SVGIcon from './SVGIcon';

const Footer = styled.footer`
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 16px 0;

    font-size: 16px;
    line-height: 26px;
    color: var(--grey-3);
`;

const FooterContainer = styled.div`
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
`;

const FooterLink = styled.a`
    color: var(--grey-3);
    font-size: 16px;
    line-height: 26px;

    &:hover {
        color: var(--grey-1);
    }
`;

const FooterLeft = styled.div`
    text-align: center;

    ${FooterLink} {
        margin: 0 4px;
    }
`;

const FooterRight = styled.div`
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;

    gap: 8px;
    margin-bottom: 8px;
`;

const FooterRightExternal = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

const FooterRightSocial = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    margin-bottom: 4px;
`;

const FooterRightSocialLink = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 32px;
    height: 32px;
    background: var(--denim-blue);
    border-radius: 50%;
`;

const FooterRightSocialIcon = styled(SVGIcon)`
    width: 16px;
    height: 16px;
    color: #fff;
`;

function MarketingFooter() {
    return (
        <Footer>
            <FooterContainer>
                <FooterLeft>
                    <FooterLink href="http://about.poki.com" target="_blank" rel="noreferrer noopener"><strong>Poki B.V.</strong></FooterLink>
                    <FooterLink href="https://goo.gl/maps/EKz1MmVJnDRY1Vb97" target="_blank" rel="noreferrer noopener">Spui 10</FooterLink>
                    , 1012 WZ • Amsterdam, The Netherlands
                </FooterLeft>
                <FooterRight>
                    <FooterRightExternal>
                        <FooterLink href="https://poki.com/en/c/privacy-statement" target="_blank" rel="noreferrer noopener">Privacy Policy</FooterLink>
                        <FooterLink href="http://poki.com/en/c/terms-of-use" target="_blank" rel="noreferrer noopener">Terms Of Use</FooterLink>
                    </FooterRightExternal>
                    <FooterRightSocial>
                        <FooterRightSocialLink href="https://www.facebook.com/Poki" target="_blank" rel="noreferrer noopener">
                            <FooterRightSocialIcon icon="icon-facebook" />
                        </FooterRightSocialLink>
                        <FooterRightSocialLink href="https://twitter.com/poki" target="_blank" rel="noreferrer noopener">
                            <FooterRightSocialIcon icon="icon-twitter" />
                        </FooterRightSocialLink>
                        <FooterRightSocialLink href="https://www.youtube.com/poki" target="_blank" rel="noreferrer noopener">
                            <FooterRightSocialIcon icon="icon-youtube" />
                        </FooterRightSocialLink>
                        <FooterRightSocialLink href="https://www.linkedin.com/company/poki" target="_blank" rel="noreferrer noopener">
                            <FooterRightSocialIcon icon="icon-linkedin" />
                        </FooterRightSocialLink>
                        <FooterRightSocialLink href="https://blog.poki.com" target="_blank" rel="noreferrer noopener">
                            <FooterRightSocialIcon icon="icon-medium" />
                        </FooterRightSocialLink>
                    </FooterRightSocial>
                </FooterRight>
            </FooterContainer>
        </Footer>
    );
}

export default MarketingFooter;
