import { useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectRenderGame } from 'src/slices/game.slice';
import { selectIsMobileOrTablet } from 'src/slices/app.slice';
import { selectIsMobileTestingMode, selectIsRemoteTestingMode } from 'src/slices/inspector.slice';

import Qr from 'src/components/ui/Qr';
import GameEmbed from 'src/components/ui/GameEmbed';
import GameUploader from 'src/components/ui/GameUploader';
import GameMobileOrientation from 'src/components/ui/GameMobileOrientation';

const GameModuleWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 100%;
    height: calc(100vh - 298px);
    padding: 16px;

    background-color: #fff;
    border-radius: 8px 8px 0 0;

    ${({ $isMobileOrTablet }) => $isMobileOrTablet && `
        height: 100%;
        border-rdaius: 0;
        padding: 0;
    `}
`;

export default function GameModule() {
    const gameModuleRef = useRef();

    const isRemoteTestingMode = useSelector(selectIsRemoteTestingMode);
    const isMobileOrTablet = useSelector(selectIsMobileOrTablet);
    const shouldRenderGame = useSelector(selectRenderGame);
    const isMobileTestingMode = useSelector(selectIsMobileTestingMode);

    return (
        <GameModuleWrapper $isMobileOrTablet={isMobileOrTablet} ref={gameModuleRef}>
            {!shouldRenderGame && <GameUploader />}
            {shouldRenderGame && !isRemoteTestingMode && (
                <>
                    {isMobileTestingMode && !isMobileOrTablet && (
                        <GameMobileOrientation />
                    )}
                    <GameEmbed gameModule={{ height: gameModuleRef.current?.offsetHeight, width: gameModuleRef.current?.offsetWidth }} />
                </>
            )}
            {shouldRenderGame && isRemoteTestingMode && <Qr />}
        </GameModuleWrapper>
    );
}
