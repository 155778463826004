import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { setCurrentDeviceID, setScalingTestDevice } from 'src/slices/app.slice';

import { SCALING_SIZES } from 'src/vars';

const SizeSwitchWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const SizeSwitchButtonStyles = css`
    display: inline-block;
    padding: 6px 16px;
    background: var(--grey-7);
    border-radius: 10px;
    border: none;
    margin: 0 0 0 8px;

    font-weight: 700;
    font-size: 12px;
    color: var(--grey-3);
    cursor: pointer;

    ${({ $isSelected }) => $isSelected && `
        background: var(--poki-blue);
        color: #fff;
        pointer-events: none;
    `}

    &:hover {
        background: var(--poki-hover);
        color: #fff;
    }
`;

const SizeSwitchButton = styled.button`
    ${SizeSwitchButtonStyles}
`;

const SizeSwitchDetails = styled.details`
    ${SizeSwitchButtonStyles}
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;

    ${({ $isDeviceSelected }) => $isDeviceSelected && `
        background: var(--poki-blue);
        color: #fff;
    `}

    ${SizeSwitchButton} {
        margin: 0;
        border-radius: 0;
        padding: 6px 12px;
        text-align: left;

        &:first-child {
            border-radius: 10px 10px 0 0;
        }

        &:last-child {
            border-radius: 0 0 10px 10px;
        }
    }
`;

const SizeSwitchDropdownSummary = styled.summary`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;

    width: 180px;
`;

const SizeSwitchDropdownValues = styled.div`
    position: absolute;
    bottom: 32px;
    left: 0;

    display: flex;
    flex-direction: column;
    width: 100%;

    background: var(--grey-7);
    border-radius: 10px;
`;

export default function SizeSwitch({ onSizeSwitch }) {
    const dispatch = useDispatch();

    const detailsRef = useRef(null);

    const [size, setSize] = useState(SCALING_SIZES[0].value);
    const [isDeviceSelected, setIsDeviceSelected] = useState(false);
    const [dynamicDeviceLabel, setDynamicDeviceLabel] = useState('');
    const [width, height] = size;

    const handleSizeSwitch = ({ options }) => {
        const { id, value, device, label = '' } = options;

        setSize(value);
        onSizeSwitch(value); // callback to parent
        dispatch(setScalingTestDevice({ device }));
        dispatch(setCurrentDeviceID({ id }));

        setDynamicDeviceLabel(label);
        setIsDeviceSelected(!!label);
    };

    const handleDetailsToggle = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (detailsRef.current.hasAttribute('open')) {
            detailsRef.current.removeAttribute('open');
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (detailsRef.current && !detailsRef.current.contains(event.target)) {
                detailsRef.current.removeAttribute('open');
            }
        };

        window.addEventListener('click', handleClickOutside);

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <SizeSwitchWrapper>
            {SCALING_SIZES.map(({
                label, value, device, values = [],
            }) => {
                if (values.length > 0) {
                    return (
                        <SizeSwitchDetails
                            key={value}
                            ref={detailsRef}
                            $isDeviceSelected={isDeviceSelected}
                        >
                            <SizeSwitchDropdownSummary>
                                {`Device: ${dynamicDeviceLabel || 'no device selected'}`}
                            </SizeSwitchDropdownSummary>
                            <SizeSwitchDropdownValues onClick={handleDetailsToggle}>
                                {values.map(({ id, label: subLabel, value: subValue, device: subDevice }) => {
                                    const isSelected = width === subValue[0] && height === subValue[1];
                                    return (
                                        <SizeSwitchButton
                                            key={subLabel}
                                            $isSelected={isSelected}
                                            onClick={() => handleSizeSwitch({
                                                options: { id, value: subValue, device: subDevice, label: subLabel },
                                            })}
                                            type="button"
                                        >
                                            {subLabel}
                                        </SizeSwitchButton>
                                    );
                                })}
                            </SizeSwitchDropdownValues>
                        </SizeSwitchDetails>
                    );
                }

                const isSelected = width === value[0] && height === value[1];
                return (
                    <SizeSwitchButton
                        key={label}
                        $isSelected={isSelected}
                        onClick={() => handleSizeSwitch({ options: { value, device } })}
                        type="button"
                    >
                        {label}
                    </SizeSwitchButton>
                );
            })}
        </SizeSwitchWrapper>
    );
}

SizeSwitch.propTypes = {
    onSizeSwitch: PropTypes.func.isRequired,
};
