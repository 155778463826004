import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { selectGame } from 'src/slices/game.slice';
import { ORIENTATION } from 'src/vars';
import GameInfoMobileAd from 'src/components/ui/GameInfoMobileAd';

const MobileGamebarWrapper = styled.div`
    position: fixed;
    bottom: 12px;
    border-radius: 0 0 16px 16px;

    display: flex;
    align-items: center;
    gap: 12px;

    width: calc(100% - 24px);
    height: 64px;
    padding: 12px;
    background-color: #fff;

    ${({ $isMobileOrTablet }) => $isMobileOrTablet && `
        width: 100%;
        bottom: 0;
        border-radius: 0;

        @media (orientation: landscape) {
            display: none;
        }
    `}
`;

const MobileGamebarThumbnail = styled.div`
    width: 40px;
    height: 100%;
    padding: ${({ $thumbnail }) => ($thumbnail ? '0' : '8px')};
    background-color: var(--denim-blue);
    border-radius: 8px;
`;

const MobileGamebarImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
`;

const MobileGamebarContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const MobileGamebarTitle = styled.div`
    font-size: 16px;
    font-weight: 700;
    margin: 0;
`;

const MobileGamebarSubtitle = styled.div`
    font-size: 12px;
    color: var(--grey-5);
    margin: 0;
`;

function MobileGameBar({ mobileOrientation, isMobileOrTablet }) {
    const { gameTitle, thumbnail, teamName } = useSelector(selectGame);

    return (
        mobileOrientation === ORIENTATION.PORTRAIT && (
            <MobileGamebarWrapper $isMobileOrTablet={isMobileOrTablet}>
                <GameInfoMobileAd />

                <MobileGamebarThumbnail $thumbnail={!!thumbnail}>
                    <MobileGamebarImage src={thumbnail ?? 'assets/mini-logo-inverted.svg'} alt={thumbnail ?? 'game thumbnail'} />
                </MobileGamebarThumbnail>
                <MobileGamebarContent>
                    <MobileGamebarTitle>{gameTitle ?? 'Title'}</MobileGamebarTitle>
                    <MobileGamebarSubtitle>{teamName ?? 'Your team name'}</MobileGamebarSubtitle>
                </MobileGamebarContent>
            </MobileGamebarWrapper>
        )
    );
}

export default MobileGameBar;

MobileGameBar.propTypes = {
    mobileOrientation: PropTypes.string.isRequired,
    isMobileOrTablet: PropTypes.bool,
};

MobileGameBar.defaultProps = {
    isMobileOrTablet: false,
};
