import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectIsRemoteTestingEnv, selectPokiAnalytics, setPokiAnalytics } from 'src/slices/inspector.slice';
import { getGameStats, selectRenderGame } from 'src/slices/game.slice';
import { selectIsMobileOrTablet, selectApplicationTitle } from 'src/slices/app.slice';

import { initMixpanel } from 'src/utils/tracking';

import Header from 'src/components/ui/Header';
import GameModule from 'src/components/modules/GameModule';
import LocalTesting from 'src/components/ui/LocalTesting';
import RemoteTesting from 'src/components/ui/RemoteTesting';
import MobileMessage from 'src/components/ui/MobileMessage';
import { clearSearchParam } from 'src/utils/url';

export default function App() {
    const dispatch = useDispatch();

    const title = useSelector(selectApplicationTitle);
    const isRemoteTestingEnv = useSelector(selectIsRemoteTestingEnv);
    const shouldRenderGame = useSelector(selectRenderGame);
    const isMobileOrTablet = useSelector(selectIsMobileOrTablet);
    const pokiAnalytics = useSelector(selectPokiAnalytics);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const gameID = searchParams.get('game'); // game id on the cdn

        if (gameID) {
            dispatch(getGameStats({ detailsID: gameID }));
        }
    }, [dispatch]);

    // initialize mixpanel
    useEffect(() => {
        if (pokiAnalytics) {
            initMixpanel();
        } else {
            dispatch(setPokiAnalytics({ pokiAnalytics: false }));
        }

        clearSearchParam('analytics');
    }, [dispatch, pokiAnalytics]);

    // since devtools can be deattached, we should decide what to render based on the environment
    if (isRemoteTestingEnv) {
        return (
            <RemoteTesting />
        );
    }

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            {isMobileOrTablet && !shouldRenderGame && <MobileMessage />}
            {isMobileOrTablet && shouldRenderGame && <GameModule />}
            {!isMobileOrTablet && (
                <>
                    <Header />
                    <LocalTesting />
                </>
            )}
        </>
    );
}
