import { ofType } from 'redux-observable';
import { filter, map } from 'rxjs';

import { setGameEvent } from 'src/slices/gameEvents.slice';
import { setStepsDisabledStatus } from 'src/slices/qamodules.slice';
import { SDK_EVENTS } from 'src/vars';

function createEpic({ action$, state$, event, stepKey, stepDisabled = false }) {
    return action$.pipe(
        ofType(setGameEvent.type),
        filter((action) => action.payload.event === event),
        map(() => setStepsDisabledStatus({ stepsDisabledStatus: {
            ...state$.value.qaModules.stepsDisabledStatus,
            [stepKey]: stepDisabled,
        } })),
    );
}

const qaModulesEpics = {
    initGameLoadingFinishedEpic: (action$, state$) => (
        createEpic({
            action$,
            state$,
            event: SDK_EVENTS.GAME_LOADING_FINISHED,
            stepKey: 'sdk-basics-game-loading-finished',
            stepDisabled: false,
        })
    ),
    initCommercialBreaksEpic: (action$, state$) => (
        createEpic({
            action$,
            state$,
            event: SDK_EVENTS.COMMERCIAL_BREAKS,
            stepKey: 'ad-implementation-commercial-breaks',
            stepDisabled: false,
        })
    ),
    initRewardedBreaksEpic: (action$, state$) => (
        createEpic({
            action$,
            state$,
            event: SDK_EVENTS.REWARDED_BREAKS,
            stepKey: 'ad-implementation-rewarded-breaks',
            stepDisabled: false,
        })
    ),
};

export default qaModulesEpics;
