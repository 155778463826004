import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import clamp from 'src/utils/clamp';

import SVGIcon from 'src/components/ui/SVGIcon';

const MobileNavigationWrapper = styled.div`
    position: relative;
    width: 1px;
    height: 100%;
    z-index: 1;
`;

const MobileNavigationPill = styled.div`
    position: absolute;
    top: var(--mobileNavDragY);
    left: 0;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    width: 62px;
    height: 46px;
    padding: 0 4px;

    background-color: #fff;
    border-radius: 0 16px 16px 0;
    box-shadow: 0 16px 32px rgba(0, 0, 0, .24);

    cursor: grab;
    user-select: none;
`;

const MobileNavigationIcon = styled.div`
    width: 24px;
    height: 24px;
    background: url('assets/mini-logo.svg') center/contain no-repeat;
`;

const StyledChevronLeft = styled(SVGIcon)`
    width: 12px;
    height: 12px;
    color: var(--poki-blue);
`;

const OFFSET = 24; // offset from the top of the screen
const DRAG_THRESHOLD = 5; // how far the user has to drag before we consider it a drag

function MobileNavigationTouch() {
    const clientHeight = useRef(0);
    const containerRef = useRef(null);
    const timeoutRef = useRef(null);

    const [top, setTop] = useState(OFFSET);
    const [isDragging, setIsDragging] = useState(false);

    const dropHandler = (event) => {
        event.preventDefault();
        // if not dragging then it's just a click and we should exit the game
        if (isDragging) {
            setIsDragging(false);
        }
    };

    const dragHandler = (event) => {
        event.preventDefault();

        if (event?.touches?.[0]) {
            // calculate the new position but clamp it to prevent off screen positioning
            const { clientY } = event.touches[0]; // get the first touch point
            const nextTop = clamp(clientY - OFFSET, OFFSET, clientHeight.current - (OFFSET * 2));

            // this gives some tolerance for a pill position change to prevent micro movements from triggering a pill change
            const isDraggingActive = top + DRAG_THRESHOLD < nextTop || top - DRAG_THRESHOLD > nextTop;

            if (nextTop !== top) {
                setTop(nextTop);
            }

            if (isDraggingActive) {
                setIsDragging(true);
            }
        }
    };

    useEffect(() => {
        const handleOrientationChange = () => {
            timeoutRef.current = setTimeout(() => {
                clientHeight.current = window.innerHeight - OFFSET;
                setTop(OFFSET); // Reset the position
            }, 100);
        };

        clientHeight.current = window.innerHeight - OFFSET;

        // update the client height when the orientation changes
        window.addEventListener('orientationchange', handleOrientationChange);

        // Add overflow: hidden to html, body when component mounts for iOS prevent scroll
        document.documentElement.style.overflow = 'hidden';
        document.body.style.overflow = 'hidden';

        return () => {
            clearTimeout(timeoutRef.current);
            window.removeEventListener('orientationchange', handleOrientationChange);

            // reset the overflow
            document.documentElement.style.overflow = '';
            document.body.style.overflow = '';
        };
    }, []);

    const position = {
        '--mobileNavDragY': `${top}px`,
    };

    return (
        <MobileNavigationWrapper>
            <MobileNavigationPill
                ref={containerRef}
                style={position}
                onMouseMove={dragHandler}
                onTouchMove={dragHandler}
                onTouchEnd={dropHandler}
                onMouseUp={dropHandler}
            >
                <StyledChevronLeft icon="chevronleft" />
                <MobileNavigationIcon />
            </MobileNavigationPill>
        </MobileNavigationWrapper>
    );
}

export default MobileNavigationTouch;
