import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { clearGameEvents } from 'src/slices/gameEvents.slice';
import { selectGameWarnings } from 'src/slices/game.slice';

import QAModules from 'src/components/modules/QAModules';
import EventLogModule from 'src/components/modules/EventLogModule';
import StatusBadge from 'src/components/ui/StatusBadge';
import WarningsModule from 'src/components/modules/WarningsModule';
import Accordion from 'src/components/ui/Accordion';

import { STATUS_BADGE } from 'src/vars';

const LocalTestingWarningsModuleTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const getAccordionItems = ({ warnings }) => {
    const hasWarnings = warnings?.length > 0;

    return [
        {
            id: 'qa-modules',
            title: 'QA Modules',
            component: <QAModules />,
        },
        {
            id: 'event-log',
            title: 'Event Log',
            action: 'Clear',
            onAction: () => clearGameEvents(),
            component: <EventLogModule />,
        },
        {
            id: 'warnings',
            title: (
                <LocalTestingWarningsModuleTitle>
                    Warnings
                    {' '}
                    {hasWarnings && <StatusBadge variant={STATUS_BADGE.WARNING} title={warnings.length} />}
                    {' '}
                </LocalTestingWarningsModuleTitle>
            ),
            component: <WarningsModule />,
        },
    ];
};

function LeftBlockAccordionLayout() {
    const dispatch = useDispatch();

    const [activeId, setActiveId] = useState('qa-modules');

    const warnings = useSelector(selectGameWarnings);

    const handleAccordionClick = (e, { id, isOpen }) => {
        e.preventDefault();

        setActiveId(isOpen ? activeId : id);
    };

    return (
        getAccordionItems({ warnings }).map(({
            id,
            title,
            component,
            action,
            onAction,
        }) => {
            const isOpen = id === activeId;

            return (
                <Accordion
                    key={id}
                    title={title}
                    action={action}
                    isOpen={isOpen}
                    handleClick={(e) => handleAccordionClick(e, { id, isOpen })}
                    handleActionClick={() => action && dispatch(onAction())}
                >
                    {component}
                </Accordion>
            );
        })
    );
}

export default LeftBlockAccordionLayout;
