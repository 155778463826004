import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import SVGIcon from 'src/components/ui/SVGIcon';

import { selectMobileOrientation, setMobileOrientation } from 'src/slices/game.slice';
import { ORIENTATION } from 'src/vars';

const GameMobileOrientation = styled.button`
    position: absolute;
    top: 16px;
    right: 16px;

    display: flex;
    align-items: center;
    gap: 8px;

    border: none;
    background: none;

    color: var(--poki-blue);
    font-weight: bold;
    cursor: pointer;
    
    &:hover {
        text-decoration: underline;
    }
`;

const GameMobileOrientationText = styled.span`
    margin: 2px 0 0;
`;

const GameMobileOrientationIcon = styled(SVGIcon)`
    width: 18px;
    height: 18px;
    color: var(--poki-blue);
`;

function MobileOrientation() {
    const dispatch = useDispatch();

    const mobileOrientation = useSelector(selectMobileOrientation);

    const handleMobileOrientation = () => {
        if (mobileOrientation === ORIENTATION.PORTRAIT) {
            dispatch(setMobileOrientation({ orientation: ORIENTATION.LANDSCAPE }));
        } else {
            dispatch(setMobileOrientation({ orientation: ORIENTATION.PORTRAIT }));
        }
    };

    return (
        <GameMobileOrientation onClick={handleMobileOrientation}>
            <GameMobileOrientationIcon icon="rotate-device" />
            <GameMobileOrientationText>Rotate</GameMobileOrientationText>
        </GameMobileOrientation>
    );
}

export default MobileOrientation;
