import axios from 'axios';
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { sendEvent } from 'src/utils/tracking';

import { getGameStats, setGameID } from 'src/slices/game.slice';

export const setUploadProgress = createAction('upload/setUploadProgress');

export const uploadGame = createAsyncThunk(
    'upload/uploadGame',
    async ({ gameFile, signal }, { dispatch, rejectWithValue }) => {
        const formData = new FormData(); // create a new formdata object
        formData.append('file', gameFile);

        // on upload progress, calculate the progress and update the state
        const onUploadProgress = (progressEvent) => {
            const uploadProgress = (progressEvent.loaded / progressEvent.total) * 100; // calculate the upload progress
            dispatch(setUploadProgress(uploadProgress));
        };

        // upload the formdata (aka game file) to the inspector-api
        const request = await axios.post('https://inspector-api.poki.io/v0/builds', formData, {
            signal,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress,
        }).then((res) => {
            const { data } = res;

            dispatch(setGameID({ gameID: `upload-${data?.id}` }));
            dispatch(getGameStats({ detailsID: `upload-${data?.id}` }));

            sendEvent('inspector-upload');
        }).catch((error) => {
            const { response } = error;

            if (error?.name === 'CanceledError') {
                return rejectWithValue({ message: 'canceled' });
            }

            const message = response?.data?.errors[0]?.title || 'Something went wrong';

            return rejectWithValue({ message });
        });

        return request;
    },
);

const initialState = {
    uploadProgress: 0,
    uploadSuccess: false,
    uploadError: null,
};

const uploadSlice = createSlice({
    name: 'upload',
    initialState,
    reducers: {
        setInitialState: (state) => {
            state.uploadProgress = initialState.uploadProgress;
            state.uploadSuccess = initialState.uploadSuccess;
            state.uploadError = initialState.uploadError;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadGame.fulfilled, (state) => {
                state.uploadProgress = 100;
                state.uploadSuccess = true;
                state.uploadError = null;
            })
            .addCase(uploadGame.rejected, (state, action) => {
                if (action.payload.message === 'canceled') {
                    state.uploadProgress = initialState.uploadProgress;
                    state.uploadSuccess = initialState.uploadSuccess;
                    state.uploadError = initialState.uploadError;
                } else {
                    state.uploadProgress = 100;
                    state.uploadError = action.payload.message;
                    state.uploadSuccess = false;
                }
            })
            .addCase(setUploadProgress, (state, action) => {
                state.uploadProgress = action.payload;
                state.uploadError = null;
                state.uploadSuccess = false;
            });
    },
});

export const { setInitialState } = uploadSlice.actions;

export const selectUploadProgress = (state) => state.upload.uploadProgress;
export const selectUploadSuccess = (state) => state.upload.uploadSuccess;
export const selectUploadError = (state) => state.upload.uploadError;

export default uploadSlice.reducer;
