import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { selectCurrentOpenModule, selectQAModules, updateCurrentOpenModule, updateModules, updateStepsWithChecklist } from 'src/slices/qamodules.slice';
import { selectGame } from 'src/slices/game.slice';

import addStepToModule from 'src/utils/addStepToModule';

import QAModule from 'src/components/ui/QAModule';

import { SCREEN_SIZES } from 'src/vars';

const QAModulesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    order: 1;

    height: 100%;
    width: 100%;
    padding: 16px 16px 56px;

    background: #fff;
    overflow-y: auto;

    @media (min-width: ${SCREEN_SIZES.LARGE}px) {
        overflow-y: hidden;
        border-radius: 8px;
        padding: 0;
    }
`;

const QAModulesTitle = styled.h2`
    display: none;
    align-self: flex-start;
    width: 100%;

    position: sticky;
    top: 0;

    margin: 0;
    padding: 13px 16px 12px;

    font-size: 21px;
    border-bottom: 2px solid var(--grey-8);

    @media (min-width: ${SCREEN_SIZES.LARGE}px) {
        display: block;
    }
`;

const QAModulesList = styled.ul`
    flex: 1;
    padding: 0;

    @media (min-width: ${SCREEN_SIZES.LARGE}px) {
        padding: 15px 16px;
        overflow-y: auto;
    }
`;

const QAModulesListItem = styled.li`
    list-style: none;
    margin: 0 0 16px;
`;

export default function QAModules({ className }) {
    const dispatch = useDispatch();

    const { usesBeacon, checklist } = useSelector(selectGame);
    const qaModules = useSelector(selectQAModules);
    const currentOpenModule = useSelector(selectCurrentOpenModule);

    useEffect(() => {
        if (!checklist) {
            return;
        }

        dispatch(updateStepsWithChecklist({ checklist }));
    }, [dispatch, checklist]);

    const handeModuleOpen = (e, id) => {
        e.preventDefault();

        if (id === currentOpenModule) {
            dispatch(updateCurrentOpenModule({ currentOpenModule: 'all-closed' }));
        } else {
            dispatch(updateCurrentOpenModule({ currentOpenModule: id }));
        }
    };

    useEffect(() => {
        if (usesBeacon) {
            const stepAlreadyExists = qaModules.some(({ steps }) => steps.some(({ stepEpicId }) => stepEpicId === 'beaconImplementation'));

            if (stepAlreadyExists) return;

            const newStep = {
                stepName: 'Poki Beacon detected.',
                stepStatus: 'PASSED',
                stepEpicId: 'beaconImplementation',
            };

            const updatedModules = addStepToModule(qaModules, 'Privacy & Security', newStep);

            dispatch(updateModules(updatedModules));
        }
    }, [dispatch, usesBeacon, qaModules]);

    return (
        <QAModulesWrapper className={className}>
            <QAModulesTitle>QA Modules</QAModulesTitle>
            <QAModulesList>
                {qaModules.map(({
                    moduleName, moduleID, moduleIcon, steps,
                }) => (
                    <QAModulesListItem key={moduleName}>
                        <QAModule
                            steps={steps}
                            moduleID={moduleID}
                            moduleName={moduleName}
                            moduleIcon={moduleIcon}
                            isOpen={moduleID === currentOpenModule}
                            handleModuleOpen={(e) => handeModuleOpen(e, moduleID)}
                        />
                    </QAModulesListItem>
                ))}
            </QAModulesList>
        </QAModulesWrapper>
    );
}

QAModules.propTypes = {
    className: PropTypes.string,
};

QAModules.defaultProps = {
    className: '',
};
