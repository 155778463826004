import PropTypes from 'prop-types';

import 'src/assets/svg/cat.svg';
import 'src/assets/svg/check.svg';
import 'src/assets/svg/chevronleft.svg';
import 'src/assets/svg/clock.svg';
import 'src/assets/svg/closefullscreen.svg';
import 'src/assets/svg/coin.svg';
import 'src/assets/svg/cross.svg';
import 'src/assets/svg/document.svg';
import 'src/assets/svg/exclamation.svg';
import 'src/assets/svg/gamepad.svg';
import 'src/assets/svg/gamepadfolder.svg';
import 'src/assets/svg/graph.svg';
import 'src/assets/svg/icon-facebook.svg';
import 'src/assets/svg/icon-linkedin.svg';
import 'src/assets/svg/icon-medium.svg';
import 'src/assets/svg/icon-twitter.svg';
import 'src/assets/svg/icon-youtube.svg';
import 'src/assets/svg/login.svg';
import 'src/assets/svg/mini-logo.svg';
import 'src/assets/svg/minus.svg';
import 'src/assets/svg/mobile-check.svg';
import 'src/assets/svg/monetization.svg';
import 'src/assets/svg/play.svg';
import 'src/assets/svg/playground-integration.svg';
import 'src/assets/svg/plus.svg';
import 'src/assets/svg/privacy-security.svg';
import 'src/assets/svg/question.svg';
import 'src/assets/svg/refresh.svg';
import 'src/assets/svg/rocket.svg';
import 'src/assets/svg/rotate-device.svg';
import 'src/assets/svg/scale.svg';
import 'src/assets/svg/sdk-core.svg';
import 'src/assets/svg/speechbubble.svg';
import 'src/assets/svg/technical-stability.svg';
import 'src/assets/svg/trash.svg';
import 'src/assets/svg/code.svg';

// Use this component to render SVG icons
// example: <SVGIcon icon="gamepad" />

export default function SVGIcon({ className, icon }) {
    return (
        <svg className={className}>
            <use xlinkHref={`#${icon}`} />
        </svg>
    );
}

SVGIcon.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string.isRequired,
};

SVGIcon.defaultProps = {
    className: undefined,
};
