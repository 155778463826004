import styled from 'styled-components';

import SVGIcon from 'src/components/ui/SVGIcon';

const HeaderWrapper = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 0 auto;
    padding: 24px 40px;
`;

const StyledHeaderSVGIconBackground = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    clip-path: polygon(0 0, 100% 0, 100% ${Math.random() * 50 + 50}%, 0 ${Math.random() * 50 + 50}%);

    background: url('/assets/coins-background.svg') no-repeat top left / cover;
    width: 100%;
    height: 420px;
`;

const HeaderNavigationBar = styled.div`
    display: flex;
`;

const HeaderNavigationAnchor = styled.a`
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0 16px;
    white-space: nowrap;

    font-weight: 700;
    font-size: 18px;
    line-height: 20px;

    text-decoration: none;
    color: var(--denim-blue);

    &:hover {
        color: var(--poki-blue);
    }
`;

const HeaderLogo = styled.img`
    width: 144px;
`;

const StyledHeaderNavigationSVGIcon = styled(SVGIcon)`
    width: 18px;
    height: 18px;
`;

const MENU_ITEMS = [
    {
        title: 'Developer Guide',
        to: 'https://developers.poki.com/guide',
        icon: <StyledHeaderNavigationSVGIcon icon="document" />,
    },
    {
        title: 'Poki SDK',
        to: 'https://sdk.poki.com/',
        icon: <StyledHeaderNavigationSVGIcon icon="code" />,
    },
    {
        title: 'Sign In',
        to: 'https://app.poki.dev/signin',
        icon: <StyledHeaderNavigationSVGIcon icon="login" />,
    },
];

export default function Header() {
    return (
        <>
            <StyledHeaderSVGIconBackground />
            <HeaderWrapper>
                <HeaderNavigationAnchor href="/">
                    <HeaderLogo src="/assets/logo.svg" alt="Poki for Developers - Beta" />
                </HeaderNavigationAnchor>
                <HeaderNavigationBar>
                    {MENU_ITEMS.map((item) => (
                        <HeaderNavigationAnchor key={item.to} href={item.to} target="_blank">
                            {item.icon}
                            {item.title}
                        </HeaderNavigationAnchor>
                    ))}
                </HeaderNavigationBar>
            </HeaderWrapper>
        </>
    );
}
