import { ofType } from 'redux-observable';
import {
    concat, EMPTY, filter, map, mergeMap, of,
} from 'rxjs';

import {
    selectIsLocalTestingEnv,
    selectIsMobileTestingMode,
    setMobileAdsOpportunity,
    setShowModules,
    setTestingMode,
} from 'src/slices/inspector.slice';
import {
    SDKAdsCompleted,
    SDKAdsStarted,
    SDKGameplayStart,
    SDKGameplayStop,
} from 'src/utils/sdkDefinitions';
import { selectRenderGame, setRenderGame } from 'src/slices/game.slice';
import { setInitialState as setInitialStateEvents } from 'src/slices/gameEvents.slice';
import { gameFilteredMessage$ } from 'src/epics/gameEpic';

// inspectorDeviceSwitchEpic is waiting for the setTestingMode action to be dispatched
// and then it will reset event log and qa modules state to initial state
export function inspectorDeviceSwitchEpic(action$) {
    return action$.pipe(
        ofType(setTestingMode.type),
        mergeMap(() => concat(
            of(setInitialStateEvents()),
        )),
    );
}

export function inspectorSetShowModulesEpic(action$, state$) {
    const isLocalTestingEnv = selectIsLocalTestingEnv(state$.value);
    const renderGame = selectRenderGame(state$.value);

    if (!isLocalTestingEnv) {
        return EMPTY;
    }

    if (renderGame) {
        return of(setShowModules({ toggle: true }));
    }

    return action$.pipe(
        ofType(setRenderGame.type),
        filter((action) => action.payload.toggle),
        map(() => setShowModules({ toggle: true })),
    );
}

export function setMobileAdsOpportunityEpic(action$, state$) {
    if (!selectIsMobileTestingMode(state$.value)) return EMPTY;

    return gameFilteredMessage$.pipe(
        filter((event) => [SDKGameplayStart, SDKGameplayStop, SDKAdsStarted, SDKAdsCompleted].includes(event.data.content.event)),
        // If we receive a gameplay start or ads completed event, we can show ads again
        // If we receive a gameplay stop or ads started event, we can't show ads
        map((event) => setMobileAdsOpportunity({ allowed: [SDKGameplayStart, SDKAdsCompleted].includes(event.data.content.event) })),
    );
}
