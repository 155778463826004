import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import SVGIcon from 'src/components/ui/SVGIcon';
import Button from 'src/components/ui/Button';

import {
    close,
    selectModal,
    setConfirmed,
    setDeclined,
} from 'src/slices/modal.slice';

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;

    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContent = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2001;

    width: 520px;
    height: 240px;
    padding: 40px 48px;
    background-color: #fff;
    border-radius: 8px;
`;

const ModalTitle = styled.h2`
    margin: 0 0 24px;
    font-size: 24px;
`;

const ModalText = styled.div`
    margin: 0 0 10px;
    font-size: 16px;
`;

const ModalActions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0 4px;
`;

const ModalClose = styled.button`
    position: absolute;
    top: 0;
    right: 0;

    padding: 20px;

    border: none;
    background-color: transparent;
    color: var(--grey-5);

    cursor: pointer;

    &:hover {
        color: var(--rose-1);
    }
`;

const ModalCloseSVGIcon = styled(SVGIcon)`
    width: 11px;
    height: 11px;
    color: inherit;
`;

export default function Modal() {
    const dispatch = useDispatch();

    const { isOpen, content } = useSelector(selectModal);

    if (!isOpen) {
        return null;
    }

    const { title, text, actions } = content;

    const handleActionClick = (action) => {
        // action is action.type so we need to map it to the action creator
        if (action === setConfirmed.type) {
            dispatch(setConfirmed());
        }
        if (action === setDeclined.type) {
            dispatch(setDeclined());
        }
    };

    const handleClickClose = () => {
        dispatch(close());
    };

    return (
        <>
            <ModalOverlay onClick={handleClickClose} />
            <ModalContent>
                <ModalTitle>{title}</ModalTitle>
                <ModalText>{text}</ModalText>
                {actions && actions.length > 0 && (
                    <ModalActions>
                        {actions.map((action) => (
                            <Button
                                key={action.label}
                                isPrimary={action?.isPrimary}
                                onClick={() => handleActionClick(action?.action)}
                            >
                                {action.label}
                            </Button>
                        ))}
                    </ModalActions>
                )}
                <ModalClose onClick={handleClickClose}>
                    <ModalCloseSVGIcon icon="cross" />
                </ModalClose>
            </ModalContent>
        </>
    );
}
