import mixpanel from 'mixpanel-browser';

let mixpanelInitialized = false;

export const initMixpanel = () => {
    if (!mixpanelInitialized && process.env.NODE_ENV === 'production') {
        mixpanel.init('375313efebc23edf9976bada3cf93775');
        mixpanelInitialized = true;
    }
};

export const sendEvent = (event, props) => {
    if (!mixpanelInitialized) {
        return;
    }

    mixpanel.track(event, props);
};
