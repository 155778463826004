import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import clamp from 'src/utils/clamp';
import { selectMobileOrientation } from 'src/slices/game.slice';

import SVGIcon from 'src/components/ui/SVGIcon';

import { ORIENTATION } from 'src/vars';

const MobileNavigationWrapper = styled.div`
    position: relative;
    width: 1px;
    height: 100%;
    z-index: 1;
`;

const MobileNavigationPill = styled.div`
    position: absolute;
    top: var(--mobileNavPosY);
    left: 0;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    width: 62px;
    height: 46px;
    padding: 0 4px;

    background-color: #fff;
    border-radius: 0 16px 16px 0;
    box-shadow: 0 16px 32px rgba(0, 0, 0, .24);

    cursor: grab;
    user-select: none;
`;

const MobileNavigationIcon = styled.div`
    width: 24px;
    height: 24px;
    background: url('assets/mini-logo.svg') center/contain no-repeat;
`;

const StyledChevronLeft = styled(SVGIcon)`
    width: 12px;
    height: 12px;
    color: var(--poki-blue);
`;

const MobileNavigationOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: auto;
`;

function MobileNavigationMouse({ iframeRef }) {
    const [isDragging, setIsDragging] = useState(false);
    const [sliderValue, setSliderValue] = useState(5);

    const mobileOrientation = useSelector(selectMobileOrientation);

    const handleMouseDown = () => {
        setIsDragging(true);
    };

    const position = {
        '--mobileNavPosY': `${sliderValue}%`,
    };

    useEffect(() => {
        setSliderValue(5);
    }, [mobileOrientation]);

    useEffect(() => {
        const iframeRect = iframeRef.current.getBoundingClientRect();

        const handleMouseMove = (e) => {
            e.preventDefault();

            if (isDragging) {
                const positionInPixels = e.clientY - iframeRect.top;
                const percentage = (positionInPixels / iframeRect.height) * 100;

                switch (mobileOrientation) {
                case ORIENTATION.PORTRAIT:
                    setSliderValue(clamp(percentage, 5, 90));
                    break;
                case ORIENTATION.LANDSCAPE:
                    setSliderValue(clamp(percentage, 5, 80));
                    break;
                default:
                    break;
                }
            }
        };

        const handleMouseUp = (e) => {
            e.preventDefault();
            setIsDragging(false);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [iframeRef, isDragging, mobileOrientation]);

    return (
        <>
            <MobileNavigationWrapper>
                <MobileNavigationPill
                    style={position}
                    onMouseDown={handleMouseDown}
                >
                    <StyledChevronLeft icon="chevronleft" />
                    <MobileNavigationIcon />
                </MobileNavigationPill>
            </MobileNavigationWrapper>
            {isDragging && <MobileNavigationOverlay />}
        </>
    );
}

export default MobileNavigationMouse;

MobileNavigationMouse.propTypes = {
    iframeRef: PropTypes.shape({
        current: PropTypes.instanceOf(Element),
    }).isRequired,
};
