// Utility function to add a step to a module
export default function addStepToModule(modules, moduleName, step) {
    return modules.map((module) => {
        if (module.moduleName === moduleName) {
        // Create a new module object with the added step
            return {
                ...module,
                steps: [...module.steps, step],
            };
        }
        return module; // Return other modules as they are
    });
}
