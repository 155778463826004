import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'src/components/ui/Button';
import SVGIcon from 'src/components/ui/SVGIcon';

import { SCREEN_SIZES } from 'src/vars';

const ActionButtonText = styled.span`
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 4px;
`;

const ActionButtonWrapper = styled(Button)`
    color: var(--poki-blue);
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;

    padding: 0;
    cursor: pointer;
    text-wrap: nowrap;
    border: none;

    &:hover {
        background-color: transparent;
        color: var(--poki-blue);
    }

    &:hover ${ActionButtonText} { 
        text-decoration: underline;
    }

    &[disabled] {
        background-color: transparent;
        color: var(--grey-5);
        cursor: not-allowed;
    }
`;

const ActionButtonIcon = styled(SVGIcon)`
    display: none;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    margin: -2px 0 0;

    @media (min-width: ${SCREEN_SIZES.LARGE}px) {
        display: inline-block;
    }
`;

function ActionButton({ children, icon, onClickCallback, disabled }) {
    return (
        <ActionButtonWrapper onClick={onClickCallback} disabled={disabled}>
            {icon && <ActionButtonIcon icon={icon} /> }
            <ActionButtonText>{children}</ActionButtonText>
        </ActionButtonWrapper>
    );
}

export default ActionButton;

ActionButton.propTypes = {
    children: PropTypes.node.isRequired,
    onClickCallback: PropTypes.func.isRequired,
    icon: PropTypes.string,
    disabled: PropTypes.bool,
};

ActionButton.defaultProps = {
    icon: '',
    disabled: false,
};
