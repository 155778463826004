import { Provider } from 'react-redux';
import { createGlobalStyle } from 'styled-components';

import store from 'src/utils/store';
import fontFaces from 'src/utils/fontFaces';

import App from 'src/components/App';

// Soft CSS reset + design tokens + global styles
const GlobalStyles = createGlobalStyle`
    *, *::before, *::after {
        box-sizing: border-box;
    }
    * {
        margin: 0;
    }
    html, body {
        height: 100%;
    }
    body {
        -webkit-font-smoothing: antialiased;
        background: #EEF3FD;
        font: 400 14px/1.4 'Proxima Nova', 'Open Sans', 'Gill Sans MT', 'Gill Sans', Arial, sans-serif;
        color: var(--denim-blue);
    }
    img, picture, video, canvas, svg {
        display: block;
        max-width: 100%;
    }
    input, button, textarea, select {
        font: inherit;
    }
    p, h1, h2, h3, h4, h5, h6 {
        overflow-wrap: break-word;
    }
    #root {
        isolation: isolate;
    }

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: var(--grey-8);
    }

    &::-webkit-scrollbar-thumb {
        background: var(--grey-4);
    }

    &::-webkit-scrollbar-thumb:hover {
        background: var(--grey-5);
    }

    details summary::-webkit-details-marker {
        display: none;
    }

    /* Design tokens */
    :root {
        --denim-blue: #002b50;
        --green-1: #15c8d1;
        --green-2: #17d7d8;
        --green-3: #18e9da;
        --green-4: #17f1d8;
        --green-5: #3cf7dc;
        --green-6: #4dffe1;
        --green-7: #83ffe7;
        --green-8: #a4ffee;
        --grey-1: #3a4355;
        --grey-3: #5d6b84;
        --grey-4: #ced4da;
        --grey-5: #bac9de;
        --grey-7: #f0f5fc;
        --grey-8: #f9fbff;
        --poki-blue: #009cff;
        --poki-hover: #0097f5;
        --poki-hover-1: #005f99;
        --purple-1: #c977e8;
        --purple-2: #d490f2;
        --purple-3: #da9ef3;
        --purple-4: #dca7f2;
        --purple-5: #e0aef5;
        --purple-6: #e1b5f7;
        --purple-7: #eac0fc;
        --purple-8: #ebccff;
        --rose-1: #ff7690;
        --rose-2: #ff8aa6;
        --rose-3: #ff99b1;
        --rose-4: #ffa4ba;
        --rose-5: #ffa9be;
        --rose-6: #ffaec5;
        --rose-7: #ffc2d6;
        --rose-8: #ffc9dd;
        --yellow-1: #ff9e00;
        --yellow-2: #ffaf00;
        --yellow-3: #ffc500;
        --yellow-4: #ffd100;
        --yellow-5: #ffdc00;
        --yellow-6: #ffe200;
        --yellow-7: #faeb59;
        --yellow-8: #ffed85;

        --data-orange-1: #ffe5d2;
        --data-green-1: #d2f3de;
        --data-blue-1: #d4efff;
        --data-red-1: #fdd2d2;
    }
`;

export default function Root() {
    return (
        <>
            <style>{fontFaces}</style>
            <GlobalStyles />
            <Provider store={store}>
                <App />
            </Provider>
        </>
    );
}
