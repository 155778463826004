import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BlockWrapper, PlaceholderBlock, PlaceholderSVGIcon } from 'src/components/layout/blockLayout.styles';
import LeftBlockAccordionLayout from 'src/components/layout/LeftBlockAccordionLayout';
import RecentUploadsModule from 'src/components/modules/RecentUploadsModule';
import QAModules from 'src/components/modules/QAModules';
import Toolbar from 'src/components/ui/Toolbar';

import { SCREEN_SIZES } from 'src/vars';

const LeftBlockLayoutWrapper = styled(BlockWrapper)`
    order: 1;
`;

const LeftBlockRecentUploads = styled(PlaceholderBlock)`
    align-items: flex-start;
    justify-content: flex-start;

    padding: 16px;
    background: #fff;
    overflow-y: scroll;
`;

const StyledQAModules = styled(QAModules)`
    @media (max-width: ${SCREEN_SIZES.LARGE}px) {
        display: none;
    }
`;

const LeftBlockAccordionLayoutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 100%;

    @media (min-width: ${SCREEN_SIZES.LARGE}px) {
        display: none;
    }
`;

function LeftBlockLayout({ showModules, uploadHistory }) {
    const hasUploadHistory = Object.keys(uploadHistory).length > 0;

    const renderContent = () => {
        if (showModules) {
            return (
                <>
                    <StyledQAModules />
                    <LeftBlockAccordionLayoutWrapper>
                        <LeftBlockAccordionLayout />
                        <Toolbar />
                    </LeftBlockAccordionLayoutWrapper>
                </>
            );
        }

        if (hasUploadHistory) {
            return (
                <LeftBlockRecentUploads>
                    <RecentUploadsModule />
                </LeftBlockRecentUploads>
            );
        }

        return (
            <PlaceholderBlock>
                <PlaceholderSVGIcon icon="rocket" $width="39px" $height="92px" />
            </PlaceholderBlock>
        );
    };

    return (
        <LeftBlockLayoutWrapper>
            {renderContent()}
        </LeftBlockLayoutWrapper>
    );
}

export default LeftBlockLayout;

LeftBlockLayout.propTypes = {
    showModules: PropTypes.bool.isRequired,
    uploadHistory: PropTypes.shape({}).isRequired,
};
