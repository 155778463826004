import styled from 'styled-components';
import PropTypes from 'prop-types';

import { STATUS_BADGE } from 'src/vars';

const StatusBadgeWrapper = styled.div`
    border-radius: 25px;
    min-width: 30px;
    padding: 2px 8px;
    color: #fff;

    font-size: 12px;
    font-weight: bold;

    ${(props) => {
        switch (props.$variant) {
        case STATUS_BADGE.SUCCESS:
            return `
                background: var(--green-4);
            `;
        case STATUS_BADGE.WARNING:
            return `
                background: var(--yellow-4);
            `;
        case STATUS_BADGE.ERROR:
            return `
                background: var(--rose-4);
            `;
        default:
            return `
                background: var(--grey-4);
            `;
        }
    }}
`;

const StatusBadgeContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1px 0 0;
`;

function StatusBadge({ variant, title }) {
    return (
        <StatusBadgeWrapper $variant={variant}>
            <StatusBadgeContent>{title}</StatusBadgeContent>
        </StatusBadgeWrapper>
    );
}

export default StatusBadge;

StatusBadge.propTypes = {
    variant: PropTypes.oneOf(Object.values(STATUS_BADGE)),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

StatusBadge.defaultProps = {
    variant: STATUS_BADGE.DEFAULT,
    title: '',
};
