import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { openModal, setConfirmed, setDeclined } from 'src/slices/modal.slice';
import { selectDisableRemote, setTestingMode } from 'src/slices/inspector.slice';
import { selectGameEvents, setGameEvents } from 'src/slices/gameEvents.slice';
import { sendEvent } from 'src/utils/tracking';
import { VIEW_MODES } from 'src/vars';

const SwitchWrapper = styled.div`
    position: relative;
    width: max-content;

    display: flex;
    align-items: center;

    padding: 2px;
    border: 2px solid var(--poki-blue);
    border-radius: 6px;
`;

const SwitchLabelVal = styled.button`
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    
    padding: 4px 12px;
    border-radius: 4px;
    border: none;
    background: none;

    color: var(--grey-3);
    cursor: pointer;

    &[disabled] {
        color: var(--grey-5);
        cursor: not-allowed;
        background: var(--grey-7);
    }
`;

const SwitchInput = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    visibility: hidden;

    &:checked + ${SwitchLabelVal} {
        background: var(--poki-blue);
        transition: background 0.2s ease-in-out;
        color: #fff;
    }
`;

// generate modal content based on device (switch to remote or desktop)
function getModalContentBasedOnDevice(device, nextDevice) {
    const content = {
        title: `QA on ${nextDevice} device`,
        text: `Switching to ${nextDevice} view will clear your log/timeline and re-start your QA session. Are you sure you want to continue?`,
        actions: [
            {
                label: `No, remain on ${device}`,
                action: setDeclined.type,
            },
            {
                label: 'Yes, do the switch',
                action: setConfirmed.type,
                isPrimary: true,
            },
        ],
    };

    return content;
}

export default function DeviceSwitch() {
    const dispatch = useDispatch();

    const gameEvents = useSelector(selectGameEvents);
    const disableRemote = useSelector(selectDisableRemote);

    const [device, setDevice] = useState(VIEW_MODES.MOBILE); // set default view to mobile

    const handleViewChange = (nextViewMode) => {
        setDevice(nextViewMode);
        dispatch(setTestingMode({ testingMode: nextViewMode }));

        if (nextViewMode === VIEW_MODES.REMOTE) {
            sendEvent('remote-session-change', { device: nextViewMode });
        }
    };

    const handleModal = (nextViewMode) => {
        const content = getModalContentBasedOnDevice(device, nextViewMode);

        dispatch(openModal({ content })).then((result) => {
            const { payload } = result;

            if (payload === 'confirmed') {
                handleViewChange(nextViewMode);
            }

            if (payload === 'declined') {
                setDevice(device);
            }
        });
    };

    const handleChange = (nextViewMode) => {
        if (nextViewMode === VIEW_MODES.REMOTE || device === VIEW_MODES.REMOTE) {
            handleModal(nextViewMode);
        } else if (device === VIEW_MODES.MOBILE || device === VIEW_MODES.DESKTOP) {
            handleViewChange(nextViewMode);
            dispatch(setGameEvents(gameEvents));
        }
    };

    return (
        <SwitchWrapper>
            <SwitchInput type="radio" name="device" id="mobile" checked={device === VIEW_MODES.MOBILE} />
            <SwitchLabelVal type="button" htmlFor="mobile" onClick={() => handleChange(VIEW_MODES.MOBILE)}>
                Mobile
            </SwitchLabelVal>
            <SwitchInput type="radio" name="device" id="desktop" checked={device === VIEW_MODES.DESKTOP} />
            <SwitchLabelVal type="button" htmlFor="desktop" onClick={() => handleChange(VIEW_MODES.DESKTOP)}>
                Desktop
            </SwitchLabelVal>
            <SwitchInput type="radio" name="device" id="remote" checked={device === VIEW_MODES.REMOTE} />
            <SwitchLabelVal disabled={disableRemote} type="button" htmlFor="remote" onClick={() => handleChange(VIEW_MODES.REMOTE)}>
                Remote
            </SwitchLabelVal>
        </SwitchWrapper>
    );
}
