import axios from 'axios';
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getPersistedState } from 'src/utils/persist';
import { openNotification } from 'src/slices/notification.slice';
import { setInitialState as setInitialQAModulesState } from 'src/slices/qamodules.slice';
import { setInitialState as setInitialInspectorState } from 'src/slices/inspector.slice';
import { ORIENTATION } from 'src/vars';
import { addQueryParams, getGameForwardParams } from 'src/utils/url';

export const setRenderGame = createAction('game/setRenderGame');
export const setWarnings = createAction('game/setWarnings');

const searchParams = new URLSearchParams(window.location.search);

export const initialState = {
    entity: {
        id: '',
        url: '',
        checklist: {},
        refreshRate: 0,
        gameTitle: '',
        originalFilename: '',
        archiveSize: 0,
    },
    warnings: [],
    gameID: searchParams.get('game'),
    renderGame: false,
    isFullscreen: false,
    mobileOrientation: ORIENTATION.PORTRAIT,
};

export const getGameStats = createAsyncThunk(
    'game/getGameStats',
    async ({ detailsID }, { dispatch, getState }) => {
        const request = await axios.get(`https://inspector-api.poki.io/v0/details/${detailsID}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((res) => {
                const { data } = res;
                const state = getState();

                if (data.retry_in_seconds > 0) {
                    // random dealay between 0 and 100ms
                    const randomDelay = Math.floor(Math.random() * 100);

                    setTimeout(() => {
                        dispatch(getGameStats({ detailsID }));
                    }, data.retry_in_seconds * 1000 + randomDelay);
                }

                // update the url with the URN of the game
                const urlParams = new URLSearchParams(window.location.search); // prevent overriding other params
                urlParams.set('game', data.urn);
                window.history.replaceState(null, null, `?${urlParams.toString()}`);

                dispatch(setRenderGame({ toggle: true }));

                // Always reset QA modules and inspector state when a new game is loaded based on the detailsID and persisted state
                const {
                    stepsDisabledStatus,
                    currentOpenModule,
                    disableRemote,
                    pokiAnalytics,
                } = getPersistedState({});

                dispatch(setInitialQAModulesState({ stepsDisabledStatus, currentOpenModule }));
                dispatch(setInitialInspectorState({ disableRemote, pokiAnalytics }));

                // we need to remove any url param that starts with 'gd' to prevent duplication
                const urlParamsWithoutGD = new URLSearchParams(urlParams.toString());
                urlParamsWithoutGD.forEach((_, key) => {
                    if (key.startsWith('gd')) {
                        urlParamsWithoutGD.delete(key);
                    }
                });

                const contentTag = {
                    poki_url: encodeURIComponent(`${window.location.origin}/?${urlParamsWithoutGD.toString()}`),
                    ...getGameForwardParams(),
                };

                const iframeUrl = addQueryParams(data.url, contentTag);

                if (data?.problems && state.game.warnings.length === 0) {
                    dispatch(setWarnings(data?.problems));
                }

                return {
                    id: data.urn,
                    url: data.url,
                    iframeUrl, // this is used for the iframe src to include the contentTag
                    checklist: data.checklist,
                    refreshRate: data.retry_in_seconds,
                    originalFilename: data.details['original-filename']?.value,
                    gameTitle: data.details['game-title']?.value,
                    teamName: data.details['team-name']?.value,
                    thumbnail: data.details.thumbnail?.value,
                    archiveSize: data.details['archive-size']?.value,
                    usesBeacon: data.details['uses-beacon']?.value,
                };
            })
            .catch((err) => {
                console.error('getGameStats error', err);

                if (axios.isAxiosError(err) && err.response.status === 404) {
                    dispatch(openNotification({
                        id: 'game-stats-404',
                        type: 'error',
                        status: err.response.status,
                        message: 'Failed to fetch build: not found. Try uploading a new build.',
                    }));
                }

                return { ...initialState.entity };
            });

        return request;
    },
);

export const gameSlice = createSlice({
    name: 'game',
    initialState,
    reducers: {
        setInitialState: (state) => {
            state.entity = initialState.entity;
            state.renderGame = initialState.renderGame;
        },
        setGame: (state, action) => {
            state.entity = action.payload;
        },
        setGameID: (state, action) => {
            state.gameID = action.payload.gameID;
        },
        setRenderGame: (state, action) => {
            state.renderGame = action.payload.toggle;
        },
        setIsFullscreen: (state, action) => {
            state.isFullscreen = action.payload.toggle;
        },
        setMobileOrientation: (state, action) => {
            state.mobileOrientation = action.payload.orientation;
        },
        setWarnings: (state, action) => {
            const warningsToAdd = Array.isArray(action.payload) ? action.payload : [action.payload];

            state.warnings = [
                ...state.warnings,
                ...warningsToAdd,
            ];
        },
        addWarning: (state, action) => {
            state.warnings.push(action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getGameStats.fulfilled, (state, action) => {
                state.entity = action.payload;
            });
    },
});

export const {
    setInitialState,
    setGame,
    setGameID,
    setIsFullscreen,
    setMobileOrientation,
    addWarning,
} = gameSlice.actions;

export const selectGame = (state) => state.game.entity;
export const selectIsFullscreen = (state) => state.game.isFullscreen;
export const selectRenderGame = (state) => state.game.renderGame;
export const selectMobileOrientation = (state) => state.game.mobileOrientation;
export const selectGameWarnings = (state) => state.game.warnings;

export default gameSlice.reducer;
