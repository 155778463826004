import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import QRCode from 'qrcode';

import {
    startNetlibHost,
    stopNetlibHost,
    selectNetlibError,
    selectNetlibPeers,
    selectNetlibRemoteURL,
} from 'src/slices/netlib.slice';

const QrWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
`;

const QrCodeImageWrapper = styled.a`
    display: block;
    margin: 0 0 40px;
`;

const QrCodeImage = styled.img`
    width: 300px;
    height: 300px;
    background: var(--poki-blue);
`;

const QrCodeText = styled.div`
    color: var(--grey-3);

    p {
        margin: 0 0 4px;
    }

    strong {
        font-size: 16px;
    }

    a {
        color: var(--poki-blue);
    }
`;

const QrError = styled.strong`
    color: var(--rose-1);
`;

async function generateQRCode(url) {
    const qrCode = await QRCode.toDataURL(url, {
        errorCorrectionLevel: 'H',
        width: 300,
        color: {
            dark: '#009cff',
            light: '#ffffff',
        },
        rendererOpts: {
            quality: 1,
        },
    });
    return qrCode;
}

export default function Qr() {
    const dispatch = useDispatch();
    const [qrCode, setQrCode] = useState(null);

    const netlibError = useSelector(selectNetlibError);
    const netlibPeers = useSelector(selectNetlibPeers);
    const remoteURL = useSelector(selectNetlibRemoteURL);

    useEffect(() => {
        dispatch(
            startNetlibHost(),
        );

        return () => {
            dispatch(
                stopNetlibHost(),
            );
        };
    }, [dispatch]);

    useEffect(() => {
        if (remoteURL) {
            generateQRCode(remoteURL).then(setQrCode);
        }
    }, [remoteURL]);

    const isReadyToRender = remoteURL && qrCode;

    if (netlibError) {
        return (
            <QrWrapper>
                <QrError>{netlibError}</QrError>
            </QrWrapper>
        );
    }

    return (
        <QrWrapper>
            {!isReadyToRender && (
                <QrCodeText>
                    <p>Generating QR code and waiting for Netlib...</p>
                </QrCodeText>
            )}
            {isReadyToRender && netlibPeers.length > 1 && (
                <QrError>
                    <p>
                        WARNING: Multiple peers(
                        {netlibPeers.length}
                        ) connected.
                    </p>
                    <p>Currently we do NOT support more than one 😪</p>
                </QrError>
            )}
            {isReadyToRender && netlibPeers.length <= 1 && (
                <>
                    <QrCodeImageWrapper href={remoteURL} target="_blank" rel="noreferrer" title="Click to a open remote session in a new tab">
                        <QrCodeImage src={qrCode} alt="QR Code for remote testing" />
                    </QrCodeImageWrapper>
                    <QrCodeText>
                        <p>
                            <strong>
                                Scan this QR code or open an
                                {' '}
                                <a href={remoteURL} target="_blank" rel="noreferrer" title="Click to open a remote session in a new tab">
                                    URL
                                </a>
                            </strong>
                        </p>
                        <p>events will start firing when the game opens on your device</p>
                        <p>
                            Status:
                            {' '}
                            {netlibPeers.length > 0 ? 'Connected' : 'Disconnected'}
                        </p>
                    </QrCodeText>
                </>
            )}
        </QrWrapper>
    );
}
