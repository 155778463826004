import styled from 'styled-components';

import SVGIcon from 'src/components/ui/SVGIcon';

import { SCREEN_SIZES } from 'src/vars';

export const BlockWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;

    gap: 24px;
    min-width: 348px;

    @media (min-width: ${SCREEN_SIZES.LARGE}px) {
        max-width: 448px;
    }
`;

export const PlaceholderBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1 0%;

    width: 100%;
    height: 100%;
    min-width: 290px;
    padding: 18px 0;

    border-radius: 8px;
    background: var(--grey-8);
`;

export const PlaceholderSVGIcon = styled(SVGIcon)`
    width: ${({ $width }) => $width};
    height: ${({ $height }) => $height};
    color: var(--grey-5);
`;
