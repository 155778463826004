import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { selectCurrentDeviceID, selectMobileDevices, selectScalingTestDevice, setMobileDevices, setScalingTestDevice } from 'src/slices/app.slice';
import { setIsFullscreen } from 'src/slices/game.slice';

import SizeSwitch from 'src/components/ui/SizeSwitch';
import ActionButton from 'src/components/ui/ActionButton';
import { VIEW_MODES } from 'src/vars';

const ControlBarWrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;

    width: 100%;
    padding: 12px 18px;
    background: #fff;
    box-shadow: 0px 4px 8px rgba(9, 30, 66, 0.07), 0px 0px 2px rgba(9, 30, 66, 0.07);
`;

const ControlBarFullscreenButton = styled.button`
    margin: 0 0 0 32px;
    padding: 4px 16px;
    border-radius: 10px;
    border: none;
    background: var(--rose-2);
    color: #fff;

    font-weight: 700;
    font-size: 14px;
    cursor: pointer;

    &:hover {
        background: var(--rose-1);
    }
`;

const ControlGameDetails = styled.div`
    margin: 0 auto 0 0;
    line-height: 1.2;
`;

const ControlGameDetailsTitle = styled.h3`
    margin: 0;
    font-weight: 700;
    font-size: 20px;
    color: var(--denim-blue);
`;

const ControlGameDetailsFullscreenMode = styled.strong`
    font-size: 12px;
    color: var(--grey-5);
`;

function ControlBar({ game, setIframeSize }) {
    const dispatch = useDispatch();

    const scalingTestDevice = useSelector(selectScalingTestDevice);
    const currentDeviceID = useSelector(selectCurrentDeviceID);
    const devices = useSelector(selectMobileDevices);

    const isMobile = scalingTestDevice === VIEW_MODES.MOBILE;

    const handleFullscreenButtonClick = () => {
        dispatch(setIsFullscreen({ toggle: false }));
        dispatch(setScalingTestDevice({ device: null }));
    };

    useEffect(() => {
        if (!currentDeviceID) {
            return;
        }

        const currentDevice = devices.find((device) => device.id === currentDeviceID);

        if (currentDevice) {
            setIframeSize(currentDevice.value);
        }
    }, [currentDeviceID, devices, setIframeSize]);

    const handleRotateDevice = () => {
        const updatedDevices = devices.map((device) => {
            if (device.id === currentDeviceID) {
                let [width, height] = device.value;

                const newOrientation = device.orientation === 'portrait' ? 'landscape' : 'portrait';
                const tempWidth = width;

                // Store the current width in a temporary variable to swap the values when rotate button is clicked
                width = height;
                height = tempWidth;

                // Set the iframe size to the new width and height values so GameEmbed component can update the iframe size
                setIframeSize(device.value);

                return {
                    ...device,
                    orientation: newOrientation,
                    value: [width, height],
                };
            }

            return device;
        });

        dispatch(setMobileDevices({ devices: updatedDevices }));
    };

    const onSizeSwitch = (value) => {
        setIframeSize(value);
    };

    return (
        <ControlBarWrapper>
            <ControlGameDetails>
                <ControlGameDetailsTitle>
                    {game.gameTitle}
                </ControlGameDetailsTitle>
                <ControlGameDetailsFullscreenMode>
                    SCALING TESTS
                </ControlGameDetailsFullscreenMode>
            </ControlGameDetails>
            <SizeSwitch onSizeSwitch={onSizeSwitch} />
            <div>
                <ActionButton icon="rotate-device" onClickCallback={handleRotateDevice} disabled={!isMobile}>
                    Rotate
                </ActionButton>
            </div>
            <ControlBarFullscreenButton type="button" onClick={handleFullscreenButtonClick}>
                Close
            </ControlBarFullscreenButton>
        </ControlBarWrapper>
    );
}

export default ControlBar;

ControlBar.propTypes = {
    game: PropTypes.shape({
        gameTitle: PropTypes.string.isRequired,
    }).isRequired,
    setIframeSize: PropTypes.func.isRequired,
};
