import { createSlice } from '@reduxjs/toolkit';
import { getPersistedState } from 'src/utils/persist';
import { VIEW_MODES } from 'src/vars';

const searchParams = new URLSearchParams(window.location.search);
// this parameter comes from p4d as analytics=false so we don't initialize mixpanel
const disableAnalytics = searchParams.get('analytics') === 'false';
const { disableRemote, pokiAnalytics = true } = getPersistedState({});
const uploadHistory = getPersistedState({ id: 'poki_upload_history' });

const initialState = {
    testingMode: VIEW_MODES.MOBILE, // 'mobile', 'desktop' or 'remote' - in remote mode, it listen to external events
    testingEnv: window?.location?.search?.includes(VIEW_MODES.REMOTE) ? VIEW_MODES.REMOTE : VIEW_MODES.LOCAL, // in 'remote' the inspector will render simple UI
    showModules: false,
    disableRemote: disableRemote || false,
    pokiAnalytics: disableAnalytics ? false : pokiAnalytics,
    mobileAdsOpportunity: false,
    uploadHistory: uploadHistory || {},
};

export const inspectorSlice = createSlice({
    name: 'inspector',
    initialState,
    reducers: {
        setInitialState: (state, action) => {
            state.disableRemote = action?.payload?.disableRemote || initialState.disableRemote;
            state.pokiAnalytics = action?.payload?.pokiAnalytics || initialState.pokiAnalytics;
        },
        setTestingMode: (state, action) => {
            state.testingMode = action.payload.testingMode;
        },
        setShowModules: (state, action) => {
            state.showModules = action.payload.toggle;
        },
        setDisableRemote: (state, action) => {
            state.disableRemote = action.payload.disableRemote;
        },
        setPokiAnalytics: (state, action) => {
            state.pokiAnalytics = action.payload.pokiAnalytics;
        },
        setMobileAdsOpportunity: (state, action) => {
            state.mobileAdsOpportunity = action.payload.allowed;
        },
        setUploadHistory: (state, action) => {
            const { id } = action.payload;

            state.uploadHistory[id] = action.payload;
        },
        clearUploadHistory: (state) => {
            state.uploadHistory = {};
        },
    },
});

export const {
    setInitialState,
    setTestingMode,
    setShowModules,
    setDisableRemote,
    setPokiAnalytics,
    setMobileAdsOpportunity,
    setUploadHistory,
    clearUploadHistory,
} = inspectorSlice.actions;

export const selectTestingMode = (state) => state.inspector.testingMode;
// inspectorMode sets how we render the inspector UI
export const selectIsMobileTestingMode = (state) => state.inspector.testingMode === VIEW_MODES.MOBILE;
export const selectIsDesktopTestingMode = (state) => state.inspector.testingMode === VIEW_MODES.DESKTOP;
export const selectIsRemoteTestingMode = (state) => state.inspector.testingMode === VIEW_MODES.REMOTE;
// inspectorEnv sets where the inspector is currently running
export const selectIsLocalTestingEnv = (state) => state.inspector.testingEnv === VIEW_MODES.LOCAL;
export const selectIsRemoteTestingEnv = (state) => state.inspector.testingEnv === VIEW_MODES.REMOTE;

export const selectShowModules = (state) => state.inspector.showModules;
export const selectDisableRemote = (state) => state.inspector.disableRemote;
export const selectPokiAnalytics = (state) => state.inspector.pokiAnalytics;
export const selectMobileAdsOpportunity = (state) => state.inspector.mobileAdsOpportunity;
export const selectUploadHistory = (state) => state.inspector.uploadHistory;

export default inspectorSlice.reducer;
