import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from 'src/components/ui/Button';
import DragAndDrop from 'src/components/ui/DragAndDrop';
import UploadProgress from 'src/components/ui/UploadProgress';

import { selectGame, getGameStats, setGameID } from 'src/slices/game.slice';
import { uploadGame, selectUploadProgress } from 'src/slices/upload.slice';
import { extractAndValidateIDFromURL, isLocalURL } from 'src/utils/url';
import { setDisableRemote } from 'src/slices/inspector.slice';

const GameUploaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100%;
    width: 100%;

    gap: 26px;
`;

const GameUploaderTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const GameUploaderTitle = styled.h2`
    margin: 0;
    font-size: 24px;
`;

const GameUploaderSubtitle = styled.h3`
    margin: 0;
    font-weight: 400;
    color: var(--grey-3);
`;

const GameUploaderLink = styled.a`
    color: var(--poki-blue);
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

const StyledGameUploaderButton = styled(Button)`
    border-radius: 0 8px 8px 0;
`;

const GameUploaderInputWrapper = styled.form`
    display: flex;
    width: 100%;
`;

const GameUploaderInputField = styled.input`
    width: 100%;

    padding: 4px 10px;
    border: 1px solid var(--grey-5);
    border-width: 1px 0 1px 1px;
    border-radius: 8px 0 0 8px;

    font-size: 16px;
    color: var(--grey-3);

    &::placeholder {
        color: var(--grey-5);
    }

    &:focus {
        outline: none;
        border-color: var(--poki-blue);
    }

    &[disabled] {
        border-color: var(--grey-7);
    }
`;

export default function GameUploader() {
    const dispatch = useDispatch();

    const gameURLInput = useRef(null);
    const abortCtrlRef = useRef(null);

    const game = useSelector(selectGame);
    const uploadProgress = useSelector(selectUploadProgress);

    useEffect(() => {
        abortCtrlRef.current = new AbortController();
    }, []);

    const handleFileUpload = ({ gameFile }) => {
        dispatch(uploadGame({ gameFile, signal: abortCtrlRef.current?.signal }));
    };

    const handleFileUploadAbort = () => {
        if (abortCtrlRef.current) {
            abortCtrlRef.current.abort();
        }

        // reset abort controller
        abortCtrlRef.current = new AbortController();
    };

    // user can also frame a game by providing a build URL
    const handleBuildURLSubmit = (e) => {
        e.preventDefault();

        const gameURL = gameURLInput?.current?.value;
        const url = extractAndValidateIDFromURL(gameURL);
        const isLocal = isLocalURL(gameURL);

        if (url) {
            dispatch(setGameID({ gameID: url }));
            dispatch(getGameStats({ detailsID: url }));
            dispatch(setDisableRemote({ disableRemote: isLocal }));
        }
    };

    const shouldShowProggress = uploadProgress > 0;
    const shouldShowDragAndDrop = !game?.id && !shouldShowProggress;

    return (
        <GameUploaderWrapper>
            <GameUploaderTitleWrapper>
                <GameUploaderTitle>Upload your game</GameUploaderTitle>
                <GameUploaderSubtitle>
                    Please make sure to use the latest
                    {' '}
                    <GameUploaderLink target="_blank" href="https://sdk.poki.com/" rel="noreferrer nofollow">Poki SDK</GameUploaderLink>
                </GameUploaderSubtitle>
            </GameUploaderTitleWrapper>

            {shouldShowDragAndDrop && <DragAndDrop onUpload={handleFileUpload} />}

            {shouldShowProggress && <UploadProgress onAbort={handleFileUploadAbort} />}

            <GameUploaderInputWrapper onSubmit={handleBuildURLSubmit}>
                <GameUploaderInputField
                    type="url"
                    name="gameurl"
                    ref={gameURLInput}
                    required
                    placeholder="http://localhost:8080"
                    disabled={shouldShowProggress}
                />
                <StyledGameUploaderButton type="submit" disabled={shouldShowProggress} isPrimary>Open</StyledGameUploaderButton>
            </GameUploaderInputWrapper>
        </GameUploaderWrapper>
    );
}
