import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css, keyframes } from 'styled-components';

import { selectMobileAdsOpportunity } from 'src/slices/inspector.slice';

// The logic in this file is a direct copy of the component in playground.
// Please keep these in sync!
// See: https://github.com/poki/playground/blob/e2768795317f26ef6c392c1296ee611fb4639cd6/src/app/components/GameInfoMobileAd.js#L12-L15

const DELAY_BEFORE_FIRST_AD = 30 * 1000; // Start showing ads after 30 seconds
const DELAY_BEFORE_NEXT_AD = 10 * 1000; // Show the ad again if the user is still playing after N seconds
const AD_LIFETIME = 12 * 1000; // Show the ad for showGamebarAdFor seconds
const TRANSITION_DURATION = 500;

const GameInfoMobileAdFadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const GameInfoMobileAdFadeOut = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

const GameInfoMobileAdWrapper = styled.div`
    display: flex;
    justify-content: center;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    width: 100%;
    height: 100%;
    padding: 6px;

    background: #fff;
    border-radius: 0 0 16px 16px;

    animation: ${GameInfoMobileAdFadeOut} 500ms ease-in-out forwards;

    ${({ $visible }) => $visible && css`
        animation: ${GameInfoMobileAdFadeIn} 500ms ease-in-out forwards;
    `}

    ${({ $preloading }) => $preloading && `
        display: none;
        width: 0;
        height: 0;
    `}
`;

const GameInfoMobileAdImage = styled.img``;

export default function GameInfoMobileAd() {
    const [isAdVisible, setIsAdVisible] = useState(false);
    const [isAdAllowed, setIsAdAllowed] = useState(false);
    const [isPreloading, setIsPreloading] = useState(true);
    const [isFadingOut, setIsFadingOut] = useState(false);
    const adCounter = useRef(0);

    const initialTimeoutRef = useRef(null);
    const adTimeoutRef = useRef(null);
    const forceRenderTimeoutRef = useRef(null);

    const mobileAdsOpportunity = useSelector(selectMobileAdsOpportunity);

    // Initial ad opportunity handling
    useEffect(() => {
        if (initialTimeoutRef.current !== null || !mobileAdsOpportunity) return;

        initialTimeoutRef.current = setTimeout(() => {
            setIsAdAllowed(true);
            setIsPreloading(false);
        }, DELAY_BEFORE_FIRST_AD);
    }, [mobileAdsOpportunity]);

    // Subsequent ad opportunity handling
    useEffect(() => {
        if (!mobileAdsOpportunity) {
            setIsFadingOut(false);
            setIsAdVisible(false);
            clearTimeout(adTimeoutRef.current);
            return;
        }

        adCounter.current += 1;
        setIsAdVisible(true);
    }, [mobileAdsOpportunity]);

    // Ad visibility handling
    useEffect(() => {
        if (!isAdAllowed) {
            // Without this our linter complaints: Arrow function expected no return value
            return () => {};
        }

        clearTimeout(adTimeoutRef.current);

        if (mobileAdsOpportunity) {
            const timeoutDuration = isAdVisible ? AD_LIFETIME : DELAY_BEFORE_NEXT_AD;
            adTimeoutRef.current = setTimeout(() => {
                if (!isAdVisible) {
                    adCounter.current += 1;
                }
                setIsAdVisible(!isAdVisible);
                setIsFadingOut(isAdVisible);
            }, timeoutDuration);
        }

        if (!isAdVisible) {
            forceRenderTimeoutRef.current = setTimeout(() => {
                setIsFadingOut(false);
            }, TRANSITION_DURATION);
        }

        return () => clearTimeout(adTimeoutRef.current);
    }, [isAdVisible, isAdAllowed, mobileAdsOpportunity]);

    // Clear timeouts on unmount
    useEffect(() => () => {
        clearTimeout(initialTimeoutRef.current);
        clearTimeout(adTimeoutRef.current);
        clearTimeout(forceRenderTimeoutRef.current);
    }, []);

    const shouldRenderAd = isAdVisible || isFadingOut || isPreloading;

    return shouldRenderAd ? (
        <GameInfoMobileAdWrapper
            $visible={isAdAllowed && isAdVisible}
            $preloading={isPreloading}
        >
            <GameInfoMobileAdImage
                src={`/assets/gamebar-320x50-${adCounter.current % 4}.png`}
            />
        </GameInfoMobileAdWrapper>
    ) : null;
}
