/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import styled from 'styled-components';

const IndicatorBarWrapper = styled.div`
    height: 4px;
    background: var(--grey-7);
    border-radius: 4px;
    overflow: hidden;

    --bar-fill-width: ${({ $stepWidth }) => $stepWidth}%;
`;

const IndicatorBarFill = styled.div`
    display: inline-block;
    vertical-align: top;
    height: 100%;
    width: var(--bar-fill-width, 0);
    background: ${({ $isFailedStep }) => ($isFailedStep ? 'var(--rose-1)' : 'var(--poki-blue)')};
`;

export default function IndicatorBar({ stepCounter, passedSteps, failedSteps }) {
    const barFillWidth = 100 / stepCounter; // calculate the width of one bar filler

    return (
        <IndicatorBarWrapper $stepWidth={barFillWidth}>
            {/* we use indexes as keys since we don't have any other options here 😪 */}
            {[...Array(passedSteps)].map((_, i) => <IndicatorBarFill key={`indicator-bar-passed-${i}`} />)}
            {[...Array(failedSteps)].map((_, i) => <IndicatorBarFill key={`indicator-bar-failed-${i}`} $isFailedStep />)}
        </IndicatorBarWrapper>
    );
}

IndicatorBar.propTypes = {
    stepCounter: PropTypes.number,
    passedSteps: PropTypes.number,
    failedSteps: PropTypes.number,
};

IndicatorBar.defaultProps = {
    stepCounter: 0,
    passedSteps: 0,
    failedSteps: 0,
};
