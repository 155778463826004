/* eslint-disable max-len */
export const STEP_STATUS = {
    DISABLED: 'DISABLED',
    NOT_STARTED: 'NOT_STARTED',
    PASSED: 'PASSED',
    FAILED: 'FAILED',
};

export default [
    {
        moduleName: 'SDK Basics',
        moduleID: 'sdk-basics',
        moduleIcon: 'sdk-core',
        steps: [
            {
                stepKey: 'sdk-basics-init-sdk',
                stepName: 'Has the game initialized the SDK?',
                stepDescription: 'This shows us that our SDK is in your game. If it’s not found, no other SDK events will fire in this check.',
                stepErrorMessage: 'We haven’t yet detected the SDK initialization. Please make sure it is implemented.',
                stepStatus: STEP_STATUS.FAILED,
                stepEpicID: 'initSDKEpic',
            },
            {
                stepKey: 'sdk-basics-game-loading-finished',
                stepName: 'Does <em class="loading-event"><span>⌛</span> gameLoadingFinished()</em> fire when your game is done loading?',
                stepDescription: 'This event allows us to measure how many players actually get into your game so we can track your conversion to play and make the game easily accessible to as many players as possible.',
                stepErrorMessage: 'This event is required for all games on Poki. Please make sure it is implemented.',
                stepStatus: STEP_STATUS.NOT_STARTED,
                stepEpicID: 'initGameLoadingFinishedEpic',
            },
            {
                stepKey: 'sdk-basics-gameplay-start',
                stepName: 'Is a <em class="gameplay-event"><span>🎮</span> gameplayStart()</em> event fired at the start of gameplay? ',
                stepDescription: 'This event tells us that a player has actually started playing. It should be fired on a player interaction such as the first click or when a player starts moving, etc.',
                stepErrorMessage: (
                    <>
                        We haven’t yet detected a gameplayStart() event or it was fired without a
                        <strong> player interaction</strong>
                        . Please make sure it is implemented correctly.
                    </>
                ),
                stepStatus: STEP_STATUS.NOT_STARTED,
                stepEpicID: 'initGameplayStartEpic',
            },
            {
                stepKey: 'sdk-basics-gameplay-stop',
                stepName: 'Is a <em class="gameplay-event"><span>☠️</span> gameplayStop()</em> event fired at the end of gameplay?',
                stepDescription: 'This event tells us that a player has stopped playing. It should be fired whenever gameplay ends such as the end of a level or when a player dies.',
                stepErrorMessage: 'We haven’t yet detected a gameplayStop() event or it was fired before gameplayStart(). Please make sure it is implemented correctly.',
                stepStatus: STEP_STATUS.NOT_STARTED,
                stepEpicID: 'initGameplayStopEpic',
            },
            {
                stepKey: 'sdk-basics-pause-events',
                stepName: 'Are <em class="gameplay-event"><span>☠️</span> gameplayStop()</em> and <em class="gameplay-event"><span>🎮</span> gameplayStart()</em> events accurately fired when the game is paused?',
                stepDescription: 'Since pausing the game stops and restarts gameplay, there should be a gameplayStop() and gameplayStart() fired on pause and unpause.',
                stepStatus: STEP_STATUS.NOT_STARTED,
            },
            {
                stepKey: 'sdk-basics-no-duplicate-events',
                stepName: 'Are you sure no events are fired as duplicates or in unnecessary places?',
                stepDescription: 'While our systems auto-detect when two or more of the same event have been fired in a row, it’s still good practice to make sure the events are not being sent more than they should.',
                stepStatus: STEP_STATUS.NOT_STARTED,
            },
        ],
    },
    {
        moduleName: 'Technical Stability',
        moduleID: 'technical-stability',
        moduleIcon: 'technical-stability',
        steps: [
            {
                stepKey: 'technical-stability-no-crashes',
                stepName: 'Is the game free of any fatal crashes?',
                stepDescription: 'The game should always be playable, with no crashes, black screens, unresponsiveness, etc.',
                stepStatus: STEP_STATUS.NOT_STARTED,
            },
            {
                stepKey: 'technical-stability-no-bugs',
                stepName: 'Is the game free of any game-breaking bugs?',
                stepDescription: 'The game should not contain any major bugs that cause the game to break.',
                stepStatus: STEP_STATUS.NOT_STARTED,
            },
            {
                stepKey: 'technical-stability-save-progress',
                stepName: 'Does the game properly save a player’s progress?',
                stepDescription: 'The game properly saves a player’s progress and is able to return to where the player left off when they come back.',
                stepStatus: STEP_STATUS.NOT_STARTED,
            },
            {
                stepKey: 'technical-stability-incognito',
                stepName: 'Does the game work in incognito mode?',
                stepDescription: 'The game should still be playable in an incognito browser.',
                stepStatus: STEP_STATUS.NOT_STARTED,
            },
        ],
    },
    {
        moduleName: 'Mobile Optimization',
        moduleID: 'mobile-optimization',
        moduleIcon: 'mobile-check',
        steps: [
            {
                stepKey: 'mobile-optimization-playable',
                stepName: 'Is the game playable on mobile?',
                stepStatus: STEP_STATUS.NOT_STARTED,
            },
            {
                stepKey: 'mobile-optimization-scale',
                stepName: 'Does the game scale correctly for mobile devices?',
                stepDescription: 'The game should properly fill the game screen in portrait and/or landscape.',
                stepStatus: STEP_STATUS.NOT_STARTED,
            },
            {
                stepKey: 'mobile-optimization-controls',
                stepName: 'Does the game show the correct controls for mobile?',
                stepDescription: 'The game should properly show and use mobile controls (ie. it does not show keyboard controls, mouse, etc.).',
                stepStatus: STEP_STATUS.NOT_STARTED,
            },
        ],
    },
    {
        moduleName: 'Privacy & Security',
        moduleID: 'privacy-security',
        moduleIcon: 'privacy-security',
        steps: [
            {
                stepKey: 'privacy-security-external-resources',
                stepName: 'Is the game free of loading any unnecessary external resources?',
                stepDescription: 'Games should not make any third-party requests with the exceptions of multiplayer services and your own analytics. You will also need to provide Poki with a privacy policy for either of these exceptions.',
                stepStatus: STEP_STATUS.NOT_STARTED,
            },
            {
                stepKey: 'privacy-security-cookies',
                stepName: 'Are cookies for saved games clearly labeled as such? ',
                stepDescription: 'We highly recommend labeling your saved games cookies as savedgame_ or something similar so they are clearly distinguishable from other cookies.',
                stepStatus: STEP_STATUS.NOT_STARTED,
            },
            {
                stepKey: 'privacy-security-usernames',
                stepName: 'If your game has in-game usernames, are they protected with a strong profanity/inappropriate words filter?',
                stepDescription: 'Usernames in game should be limited by a profanity or inappropriate words filter in order to ensure player safety.',
                stepStatus: STEP_STATUS.NOT_STARTED,
            },
            {
                stepKey: 'privacy-security-chat',
                stepName: 'If your game has been cleared to have in-game chat, is it protected with a strong profanity/content moderation service?',
                stepDescription: 'In order to ensure player safety, we need to first evaluate the impact of chat in games on Poki. If it is deemed additive to the game, then we need to ensure it is protected with a strong profanity/content moderation service.',
                stepStatus: STEP_STATUS.NOT_STARTED,
            },
        ],
    },
    {
        moduleName: 'Playground Integration',
        moduleID: 'playground-integration',
        moduleIcon: 'playground-integration',
        steps: [
            {
                stepKey: 'playground-integration-resize',
                stepName: 'Does the game resize dynamically?',
                stepStatus: STEP_STATUS.NOT_STARTED,
            },
            {
                stepKey: 'playground-integration-adblock',
                stepName: 'Does the game work with adblock enabled and is it free of any messaging around adblock?',
                stepDescription: 'We deal with adblock detection and messaging platform-wide so please make sure your game works even with adblock and you don’t have any in-game messages that mention it.',
                stepStatus: STEP_STATUS.NOT_STARTED,
            },
            {
                stepKey: 'playground-integration-focus',
                stepName: 'Are you sure there are no focus issues and that the page does not scroll if you press the space bar or cursor keys when playing on desktop?',
                stepStatus: STEP_STATUS.NOT_STARTED,
            },
        ],
    },
    {
        moduleName: 'Ad Implementation',
        moduleID: 'ad-implementation',
        moduleIcon: 'monetization',
        steps: [
            {
                stepKey: 'ad-implementation-commercial-breaks',
                stepName: 'Are <em class="break-event"><span>🎞</span> commercialBreaks()</em> properly fired and well-placed?',
                stepDescription: 'Commercial breaks tell us that this is a natural time in the game to show a video ad such as between levels, restarting a level, or basically any scenario where the player is about to go back into gameplay.',
                stepErrorMessage: 'We haven’t yet detected a commercialBreaks() event. Please make sure it is implemented.',
                stepStatus: STEP_STATUS.NOT_STARTED,
                stepEpicID: 'initCommercialBreaksEpic',
            },
            {
                stepKey: 'ad-implementation-rewarded-breaks',
                stepName: 'Are <em class="break-event"><span>🎬</span> rewardedBreaks()</em> properly fired and well-placed?',
                stepErrorMessage: 'We haven’t yet detected a rewardedBreaks() event. Please make sure it is implemented.',
                stepStatus: STEP_STATUS.NOT_STARTED,
                stepEpicID: 'initRewardedBreaksEpic',
            },
            {
                stepKey: 'ad-implementation-ad-blocker',
                stepName: 'Is game audio muted during ads?',
                stepStatus: STEP_STATUS.NOT_STARTED,
            },
            {
                stepKey: 'ad-implementation-ad-interrupt',
                stepName: 'Are you sure ads are not interrupted by using space bar or other controls?',
                stepStatus: STEP_STATUS.NOT_STARTED,
            },
        ],
    },
];
