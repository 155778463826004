import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectGame } from 'src/slices/game.slice';
import { secondsToHHMMSS } from 'src/utils/timeHelpers';
import { selectGameEvents } from 'src/slices/gameEvents.slice';

import Stat from 'src/components/ui/Stat';
import DeviceSwitch from 'src/components/ui/DeviceSwitch';

import { SDK_EVENTS, SIZE_FORMAT_PLACEHOLDER, TIME_FORMAT_PLACEHOLDER } from 'src/vars';

const GameDetailsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 24px;

    border-radius: 0 0 8px 8px;
    padding: 16px;
    background: #fff;
`;

const GameDetailsRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const GameDetailsStats = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    gap: 8px;
`;

const GameDetailsTitle = styled.h2`
    font-size: 21px;
    color: var(--denim-blue);
    margin: 0;
`;

const getLoadingFinishedProperties = (gameEvents) => {
    const loadingFinishedEvent = gameEvents.find((e) => (
        e.event === SDK_EVENTS.GAME_LOADING_FINISHED
    ));

    return {
        timestamp: loadingFinishedEvent?.timestamp || null,
        transferSize: loadingFinishedEvent?.data?.transferSize || 0,
    };
};

const getRoundFileSize = (size) => {
    if (!size) {
        return SIZE_FORMAT_PLACEHOLDER;
    }

    const sizeToMB = size / 1000000;
    const sizeToKB = size / 1000;

    if (sizeToMB < 1) {
        return `${sizeToKB.toFixed(2)}KB`;
    }

    if (sizeToMB < 0.01) {
        return SIZE_FORMAT_PLACEHOLDER;
    }

    return `${(sizeToMB).toFixed(2)}MB`;
};

export default function GameDetails() {
    const [loadingTime, setLoadingTime] = useState(null);
    const [initialTimestamp, setInitialTimestamp] = useState(null);
    const [transferSize, setTransferSize] = useState(null);

    const game = useSelector(selectGame);
    const gameEvents = useSelector(selectGameEvents);

    const roundedFileSize = getRoundFileSize(game.archiveSize);
    const roundedTransferSize = getRoundFileSize(transferSize);

    useEffect(() => {
        setInitialTimestamp(Date.now());
    }, []);

    useEffect(() => {
        if (loadingTime) return;

        const {
            timestamp: loadingFinishedTimestamp,
            transferSize: loadingFinishedTransferSize,
        } = getLoadingFinishedProperties(gameEvents);

        if (loadingFinishedTransferSize) {
            setTransferSize(loadingFinishedTransferSize);
        }

        if (loadingFinishedTimestamp) {
            const initialUnixTimestamp = initialTimestamp / 1000;
            const finalUnixTimestamp = loadingFinishedTimestamp / 1000;

            const totalLoadingTime = finalUnixTimestamp - initialUnixTimestamp;

            if (totalLoadingTime < 1) {
                setLoadingTime('00:00:01');
                return;
            }

            setLoadingTime(secondsToHHMMSS(totalLoadingTime));
        }
    }, [gameEvents, initialTimestamp, loadingTime]);

    return (
        <GameDetailsWrapper>
            <GameDetailsRow>
                <GameDetailsTitle>
                    {game?.gameTitle || game?.originalFilename}
                </GameDetailsTitle>
                <DeviceSwitch />
            </GameDetailsRow>
            <GameDetailsStats>
                <Stat label="Total loading time" value={loadingTime || TIME_FORMAT_PLACEHOLDER} />
                <Stat label="File size" value={roundedFileSize || SIZE_FORMAT_PLACEHOLDER} />
                <Stat label="Initial download size" value={roundedTransferSize} />
                <Stat />
            </GameDetailsStats>
        </GameDetailsWrapper>
    );
}
