export const SDKAdsCompleted = 'pokiTrackingAdsStatusCompleted';
export const SDKAdsStarted = 'pokiTrackingAdsStatusStarted';
export const SDKBadBehaviour = 'bad-behavior';
export const SDKCommercialBreak = 'pokiTrackingScreenCommercialBreak';
export const SDKDisplayAdDestroy = 'pokiTrackingScreenDisplayAdDestroy';
export const SDKDisplayAdRequest = 'pokiTrackingScreenDisplayAdRequest';
export const SDKGameLoadingFinished = 'pokiTrackingScreenGameLoadingFinished';
export const SDKGameplayStart = 'pokiTrackingScreenGameplayStart';
export const SDKGameplayStop = 'pokiTrackingScreenGameplayStop';
export const SDKPokiTrackingMeasure = 'pokiTrackingMeasure';
export const SDKRewardedBreak = 'pokiTrackingScreenRewardedBreak';
export const SDKStatusInitiliazed = 'pokiTrackingSdkStatusInitialized';

export const ACCEPTED_EVENTS = [
    SDKCommercialBreak,
    SDKDisplayAdDestroy,
    SDKDisplayAdRequest,
    SDKGameLoadingFinished,
    SDKGameplayStart,
    SDKGameplayStop,
    SDKPokiTrackingMeasure,
    SDKRewardedBreak,
    SDKStatusInitiliazed,
];
