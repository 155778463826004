import PropTypes from 'prop-types';
import styled from 'styled-components';

const ButtonWrapper = styled.button`
    padding: 5px 12px;
    border: 2px solid;
    background-color: #fff;
    border-radius: 10px;

    font-size: 16px;
    font-weight: 700;
    color: var(--poki-blue);

    cursor: pointer;

    &:hover {
        background-color: var(--poki-hover);
        border-color: var(--poki-hover);
        color: #fff;
    }

    &[disabled] {
        background-color: var(--grey-5);
        border-color: var(--grey-5);
        color: #fff;

        cursor: not-allowed;
    }

    ${({ $isPrimary }) => $isPrimary && `
        color: #fff;
        border-color: var(--poki-blue);
        background-color: var(--poki-blue);
    `}
`;

export default function Button({
    children, isPrimary, disabled, onClick, className, type,
}) {
    return (
        <ButtonWrapper
            $isPrimary={isPrimary}
            onClick={onClick}
            disabled={disabled}
            className={className}
            type={type}
        >
            {children}
        </ButtonWrapper>
    );
}

Button.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    isPrimary: PropTypes.bool,
    disabled: PropTypes.bool,
    type: PropTypes.string,
};

Button.defaultProps = {
    className: '',
    isPrimary: false,
    disabled: false,
    onClick: () => {},
    type: 'button',
};
