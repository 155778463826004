import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import GameEmbed from 'src/components/ui/GameEmbed';

import { selectRenderGame } from 'src/slices/game.slice';
import { selectNetlibError, startNetlibRemote } from 'src/slices/netlib.slice';

const RemoteTestingWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
`;

const RemoteTestingSpinner = styled.video`
    width: 100px;
    height: 100px;
`;

export default function RemoteTesting() {
    const dispatch = useDispatch();

    const netlibError = useSelector(selectNetlibError);
    const shouldRenderGame = useSelector(selectRenderGame);

    useEffect(() => {
        // Netlib remote thunk will take care of connecting to the remote server
        // and setting up the game. We just need to dispatch it once.
        dispatch(startNetlibRemote());
    }, [dispatch]);

    if (netlibError) {
        return (
            <RemoteTestingWrapper>
                😵
                Error:
                {' '}
                {netlibError}
            </RemoteTestingWrapper>
        );
    }

    return (
        <RemoteTestingWrapper>
            {
                shouldRenderGame
                    ? <GameEmbed />
                    : <RemoteTestingSpinner src="/assets/spinner.mp4" autoPlay loop muted />
            }
        </RemoteTestingWrapper>
    );
}
