import {
    useEffect,
    useState,
    useRef,
    useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
    selectGame,
    selectIsFullscreen,
    selectMobileOrientation,
} from 'src/slices/game.slice';
import { selectIsRemoteTestingEnv, selectTestingMode } from 'src/slices/inspector.slice';
import { selectIsMobileOrTablet, selectScalingTestDevice } from 'src/slices/app.slice';

import SVGIcon from 'src/components/ui/SVGIcon';
import MobileNavigationMouse from 'src/components/ui/MobileNavigationMouse';
import MobileNavigationTouch from 'src/components/ui/MobileNavigationTouch';
import MobileGameBar from 'src/components/ui/MobileGameBar';
import ControlBar from 'src/components/ui/ControlBar';

import { ORIENTATION, VIEW_MODES } from 'src/vars';
import { sendEvent } from 'src/utils/tracking';

const GameEmbedWrapper = styled.div`
    --game-embed-scale-portrait: 1;
    --game-embed-scale-landscape: 1;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    height: 800px;
    width: 360px;

    padding: 12px;
    transform: scale(clamp(0, var(--game-embed-scale-portrait), 1));

    border-radius: 30px;
    background: var(--grey-1);

    ${({ $mobileOrientation }) => $mobileOrientation === ORIENTATION.LANDSCAPE && `
        position: absolute;
        transform: scale(clamp(0, var(--game-embed-scale-landscape), 1));
        width: 800px;
        height: 360px;
    `}

    ${({ $testingMode }) => $testingMode === VIEW_MODES.DESKTOP && `
        width: 100%;
        height: 100%;
        padding: 0;

        background: #fff;
        border-radius: 0;
        transform: scale(1);
    `}

    ${({ $isMobileOrTablet }) => $isMobileOrTablet && `
        padding: 0;
        width: 100%;
        height: 100dvh;

        background-color: var(--grey-7);
        border-radius: 0;
    `}

    ${({ $isRemoteTesting }) => $isRemoteTesting && `
        width: 100%;
        height: 100%;
        border-radius: 0;
        background: #fff;
        transform: scale(1);
        padding: 0;
    `}

    ${({ $isFullscreen }) => $isFullscreen && `
        width: 100%;
        height: 100%;
        border-radius: 0;
        background: #fff;

        position: fixed;
        top: 0;
        left: 0;
        padding: 0 0 64px 0; /* control bar height */
        transform: translate(0, 0);
        z-index: 2;
    `}
`;

const GameEmbedIFrame = styled.iframe`
    border: none;
    margin: 0;
    padding: 0;

    width: 100%;
    height: 100%;

    aspect-ratio: 16 / 9;
    border-radius: 20px;

    /* used in fullscreen */
    max-width: var(--maxWidth, 100%);
    max-height: var(--maxHeight, 100%);
    transition: max-width .1s linear, max-height .1s linear;

    ${({ $testingMode }) => $testingMode === VIEW_MODES.DESKTOP && `
        border-radius: 0;
        height: auto;
    `}

    ${({ $isRemoteTesting, $isMobileOrTablet }) => $isRemoteTesting && `
        border-radius: 0;
        padding-bottom: 0;

        ${$isMobileOrTablet && `
            padding-bottom: 64px;
        `}
    `}

    ${({
        $testingMode,
        $isFullscreen,
        $isRemoteTesting,
        $mobileOrientation,
    }) => !$isFullscreen && !$isRemoteTesting
    && $testingMode === VIEW_MODES.MOBILE
    && $mobileOrientation === ORIENTATION.PORTRAIT && `
        padding-bottom: 64px;
    `}

    ${({ $isFullscreen, $scalingTestDevice }) => $isFullscreen && `
        aspect-ratio: auto;
        border-radius: 0;
        height: 100%;

        ${$scalingTestDevice === VIEW_MODES.MOBILE && `
            padding: 12px;
            background: var(--grey-1);
            transform: scale(0.8);
            border-radius: 30px;
        `}
    `}

    ${({ $isMobileOrTablet, $isVisible }) => $isMobileOrTablet && `
        display: ${$isVisible ? 'block' : 'none'};
        border-radius: 0;

        @media (orientation: landscape) {
            padding-bottom: 0;
        }
    `}
`;

const GameEmbedPlayText = styled.span`
    margin: 16px 0 0 0;
    font-weight: 700;
    font-size: 20px;
    color: var(--denim-blue);
`;

const GameEmbedPlayWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const GameEmbedPlayIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 64px;
    height: 64px;

    box-shadow: 0px 4px 8px rgba(9, 30, 66, 0.07), 0px 0px 2px rgba(9, 30, 66, 0.07);

    background: #fff;
    border-radius: 50%;

    svg {
        width: 20px;
        height: 20px;
        fill: var(--grey-5);
    }
`;

const GameEmbedNotch = styled.div`
    position: absolute;
    top: 11px;
    left: 120px;

    width: 120px;
    height: 30px;
    background: var(--grey-1);
    border-radius: 0 0 18px 18px;
    z-index: 2;

    ${({ $mobileOrientation }) => $mobileOrientation === ORIENTATION.LANDSCAPE && `
        top: 110px;
        left: 11px;
        width: 30px;
        height: 120px;
        border-radius: 0 18px 18px 0;
    `}
`;

/* eslint-disable max-len */
// ⚠️ If you change these permissions, make sure to update the ones in the playground/terraform repo as well. Thanks! ⚠️
// This IFRAME_ALLOW is slightly different from the one in playground. It shouldn't contain the fullscreen permission.
const IFRAME_ALLOW = 'autoplay; camera; focus-without-user-activation *; monetization; gamepad; keyboard-map *; xr-spatial-tracking; clipboard-write; web-share; accelerometer; magnetometer; gyroscope';
const IFRAME_SANDBOX = 'allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-scripts allow-same-origin allow-downloads';
/* eslint-enable max-len */

export default function GameEmbed({ gameModule }) {
    const [iframeSize, setIframeSize] = useState(['100%', '100%']);
    const [hasGameStarted, setHasGameStarted] = useState(false);

    const iframeRef = useRef(null);
    const containerRef = useRef(null);

    const game = useSelector(selectGame);
    const isFullscreen = useSelector(selectIsFullscreen);
    const testingMode = useSelector(selectTestingMode);
    const isRemoteTesting = useSelector(selectIsRemoteTestingEnv);
    const isMobileOrTablet = useSelector(selectIsMobileOrTablet);
    const mobileOrientation = useSelector(selectMobileOrientation);
    const scalingTestDevice = useSelector(selectScalingTestDevice);

    const isMobilePlayScreen = !hasGameStarted && isMobileOrTablet;

    const scaleValue = useMemo(() => {
        const portraitScale = gameModule?.height ? gameModule.height / (800 + 64) : 1;
        const landscapeScale = gameModule?.width ? gameModule.width / (800 + 64) : 1;

        return {
            portrait: portraitScale,
            landscape: landscapeScale,
        };
    }, [gameModule]);

    const handleGameFullScreen = () => {
        setHasGameStarted(true);

        if (containerRef.current) {
            try {
                containerRef.current.requestFullscreen({ navigationUI: 'hide' }).catch(() => {});
            } catch (e) {
                console.error(e);
            }
        }
        sendEvent('remote-session-started');
    };

    useEffect(() => {
        setIframeSize(['100%', '100%']); // reset iframe size when fullscreen mode is triggered
    }, [isFullscreen]);

    return (
        <GameEmbedWrapper
            ref={containerRef}
            $isMobileOrTablet={isMobileOrTablet}
            $isFullscreen={isFullscreen}
            $isRemoteTesting={isRemoteTesting}
            $testingMode={testingMode}
            $mobileOrientation={mobileOrientation}
            style={{
                '--game-embed-scale-portrait': `${scaleValue.portrait}`,
                '--game-embed-scale-landscape': `${scaleValue.landscape}`,
            }}
            onClick={isMobilePlayScreen ? handleGameFullScreen : undefined}
        >
            {isMobilePlayScreen && (
                <GameEmbedPlayWrapper>
                    <GameEmbedPlayIcon>
                        <SVGIcon icon="play" />
                    </GameEmbedPlayIcon>
                    <GameEmbedPlayText>Play Now!</GameEmbedPlayText>
                </GameEmbedPlayWrapper>
            )}
            {(testingMode === 'mobile' && (!isMobileOrTablet && !isFullscreen && !isRemoteTesting)) && (
                <>
                    <MobileNavigationMouse iframeRef={iframeRef} />
                    <GameEmbedNotch $mobileOrientation={mobileOrientation} />
                    <MobileGameBar mobileOrientation={mobileOrientation} />
                </>
            )}
            {(isMobileOrTablet && hasGameStarted && (
                <>
                    <MobileNavigationTouch />
                    <MobileGameBar mobileOrientation={mobileOrientation} isMobileOrTablet={isMobileOrTablet} />
                </>
            ))}
            {/* Keep it updated with the latest configuration from playground's iframe */}
            <GameEmbedIFrame
                ref={iframeRef}
                $isVisible={hasGameStarted}
                $isMobileOrTablet={isMobileOrTablet}
                $isFullscreen={isFullscreen}
                $isRemoteTesting={isRemoteTesting}
                $testingMode={testingMode}
                $mobileOrientation={mobileOrientation}
                $scalingTestDevice={scalingTestDevice}
                src={game.iframeUrl}
                allow={IFRAME_ALLOW}
                scrolling="no"
                referrerPolicy="origin"
                sandbox={IFRAME_SANDBOX}
                style={{
                    // we set max-width and max-height only in fullscreen mode
                    '--maxWidth': iframeSize[0],
                    '--maxHeight': iframeSize[1],
                }}
            />
            {/* control bar is used only in fullscreen mode */}
            {isFullscreen && (
                <ControlBar game={game} setIframeSize={setIframeSize} />
            )}
        </GameEmbedWrapper>
    );
}

GameEmbed.propTypes = {
    gameModule: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number,
    }).isRequired,
};
