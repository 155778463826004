const fontsURL = 'https://a.poki-cdn.com/fonts/';

const fontFaces = `
    @font-face {
        font-family: 'Proxima Nova';
        font-display: swap;
        font-weight: 400;
        src: url(${fontsURL}proxima-nova-regular-cyrillic.woff2) format('woff2'), url(${fontsURL}proxima-nova-regular-cyrillic.woff) format('woff');
        unicode-range: U+0400-04FF
    }
    @font-face {
        font-family: 'Proxima Nova';
        font-display: swap;
        font-weight: 400;
        src: url(${fontsURL}proxima-nova-regular-greek-coptic.woff2) format('woff2'), url(${fontsURL}proxima-nova-regular-greek-coptic.woff) format('woff');
        unicode-range: U+0370-03FF
    }
    @font-face {
        font-family: 'Proxima Nova';
        font-display: swap;
        font-weight: 400;
        src: url(${fontsURL}proxima-nova-regular-latin.woff2) format('woff2'), url(${fontsURL}proxima-nova-regular-latin.woff) format('woff');
        unicode-range: U+0020-007F, U+00A0-00FF
    }
    @font-face {
        font-family: 'Proxima Nova';
        font-display: swap;
        font-weight: 400;
        src: url(${fontsURL}proxima-nova-regular-latin-ext-a.woff2) format('woff2'), url(${fontsURL}proxima-nova-regular-latin-ext-a.woff) format('woff');
        unicode-range: U+0100-017F
    }
    @font-face {
        font-family: 'Proxima Nova';
        font-display: swap;
        font-weight: 400;
        src: url(${fontsURL}proxima-nova-regular-latin-ext-b.woff2) format('woff2'), url(${fontsURL}proxima-nova-regular-latin-ext-b.woff) format('woff');
        unicode-range: U+0180-024F
    }
    @font-face {
        font-family: 'Proxima Nova';
        font-display: swap;
        font-weight: 700;
        src: url(${fontsURL}proxima-nova-bold-cyrillic.woff2) format('woff2'), url(${fontsURL}proxima-nova-bold-cyrillic.woff) format('woff');
        unicode-range: U+0400-04FF
    }
    @font-face {
        font-family: 'Proxima Nova';
        font-display: swap;
        font-weight: 700;
        src: url(${fontsURL}proxima-nova-bold-greek-coptic.woff2) format('woff2'), url(${fontsURL}proxima-nova-bold-greek-coptic.woff) format('woff');
        unicode-range: U+0370-03FF
    }
    @font-face {
        font-family: 'Proxima Nova';
        font-display: swap;
        font-weight: 700;
        src: url(${fontsURL}proxima-nova-bold-latin.woff2) format('woff2'), url(${fontsURL}proxima-nova-bold-latin.woff) format('woff');
        unicode-range: U+0020-007F, U+00A0-00FF
    }
    @font-face {
        font-family: 'Proxima Nova';
        font-display: swap;
        font-weight: 700;
        src: url(${fontsURL}proxima-nova-bold-latin-ext-a.woff2) format('woff2'), url(${fontsURL}proxima-nova-bold-latin-ext-a.woff) format('woff');
        unicode-range: U+0100-017F
    }
    @font-face {
        font-family: 'Proxima Nova';
        font-display: swap;
        font-weight: 700;
        src: url(${fontsURL}proxima-nova-bold-latin-ext-b.woff2) format('woff2'), url(${fontsURL}proxima-nova-bold-latin-ext-b.woff) format('woff');
        unicode-range: U+0180-024F
    }
`;

export default fontFaces;
