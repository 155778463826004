export const clearSearchParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(param);

    const newUrl = `${window.location.pathname}?${searchParams}`;

    window.history.replaceState({}, '', newUrl);
};

export const isLocalURL = (url) => {
    const newURL = new URL(url);
    let isLocal = false;

    const localHostnames = ['localhost', '[::1]'];
    const localIPPrefixes = ['127.'];

    if (localHostnames.includes(newURL.hostname)
        || localIPPrefixes.some((prefix) => newURL.hostname.startsWith(prefix))) {
        isLocal = true;
    }

    return isLocal;
};

export const extractAndValidateIDFromURL = (url) => {
    let stripped = url;

    if (stripped.startsWith('https://')) {
        stripped = stripped.replace(/^https:\/\//i, '');
    } else if (stripped.startsWith('http://')) {
        stripped = stripped.replace(/^http:\/\//i, 'http:');
    }

    return `external-${encodeURIComponent(stripped)}`;
};

export function addQueryParam(url, key, value) {
    if (!url || !key) return url;
    const glue = /\?/.test(url) ? '&' : '?';
    const val = value ? `=${value}` : '';
    return [url, glue, key, val].join('');
}

export function addQueryParams(url, params) {
    return Object.keys(params).reduce((u, key) => (
        addQueryParam(u, key, params[key])
    ), url);
}

export const getGameForwardParams = () => {
    const searchParams = new URLSearchParams(window.location.search);

    const gameForwardParams = {};

    new URLSearchParams(searchParams)
        .forEach((value, key) => {
            if (key.toLowerCase().startsWith('gd')) {
                gameForwardParams[key] = value;
            }
        });

    return gameForwardParams;
};
