// Same as Playground and Observer.
// Same as: https://support.cloudflare.com/hc/en-us/articles/229373388-Cache-Content-by-Device-Type-Mobile-Tablet-Desktop-
function isMobile() {
    // eslint-disable-next-line max-len
    return /(?:phone|windows\s+phone|ipod|blackberry|(?:android|bb\d+|meego|silk|googlebot) .+? mobile|palm|windows\s+ce|opera mini|avantgo|mobilesafari|docomo|kaios)/i.test(navigator.userAgent);
}

function isTablet() {
    return /(?:ipad|playbook|(?:android|bb\d+|meego|silk)(?! .+? mobile))/i.test(navigator.userAgent);
}

function isIpadOS() {
    return window.navigator.platform === 'MacIntel' && typeof window.navigator.standalone !== 'undefined' && navigator.maxTouchPoints > 1;
}

const isMobileOrTablet = isMobile() || isTablet() || isIpadOS();

export default isMobileOrTablet;
