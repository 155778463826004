import { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import QAModuleStep from 'src/components/ui/QAModuleStep';
import IndicatorBar from 'src/components/ui/IndicatorBar';
import SVGIcon from 'src/components/ui/SVGIcon';

import { STEP_STATUS } from 'src/assets/qamodules/default';

export const QAModuleWrapper = styled.details`
    position: relative;
    padding: 16px 16px 24px;
    border-radius: 8px;
    background: #fff;
    height: 100%;
    box-shadow: 0px 4px 8px rgba(9, 30, 66, 0.07), 0px 0px 2px rgba(9, 30, 66, 0.07);
    container-type: inline-size;

    ${({ $isDisabled }) => $isDisabled && `
        background: var(--grey-8);
        box-shadow: none;

        pointer-events: none;

        --qa-module-title-color: var(--grey-5);
        --qa-module-icon-wrapper-bg: var(--grey-8);
    `}

    ${({ $hasCompletedSteps }) => $hasCompletedSteps && `
        --qa-module-svg-icon-color: var(--poki-blue);
    `}
`;

const QAModuleSummary = styled.summary`
    display: flex;
    align-items: center;

    width: 100%;
    padding: 0 26px 0 0;
    gap: 0 12px;

    border: none;
    color: var(--grey-5);
    background: none;

    cursor: pointer;
    position: relative;

    ${QAModuleWrapper}[open] & {
        padding: 0 26px 24px 0;
    }
`;

const QAModuleIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    grid-area: icon;

    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: var(--qa-module-icon-wrapper-bg, var(--grey-7));
`;

const QAModuleSVGIcon = styled(SVGIcon)`
    width: 32px;
    height: 32px;
    color: var(--qa-module-svg-icon-color);
`;

const QAModuleSummaryTextWrap = styled.div`
    align-self: center;
    text-align: left;
    margin: 0 auto 0 0;
`;

const QAModuleTitle = styled.strong`
    font-size: 16px;
    color: var(--qa-module-title-color, var(--poki-blue));

    @container (width < 255px) {
        display: none;

        ${QAModuleWrapper}[open] & {
            display: block;
        }
    }

`;

const QAModuleErrorNum = styled.div`
    position: absolute;
    top: 75px;
    left: 16px;

    font-size: 12px;
    color: var(--rose-1);
    margin: 4px 0 0;
`;

const QAModuleStatus = styled.div`
    margin: 4px 0 0;
    width: 100%;
    justify-self: center;
    text-align: left;
`;

const QAModuleStatusText = styled.strong`
    display: block;
    font-size: 12px;

    ${({ $hasCompletedSteps }) => $hasCompletedSteps && `
    &::first-letter {
        color: var(--poki-blue);
    }
    `}
    ${({ $allStepsFullyCompleted }) => $allStepsFullyCompleted && `
        color: var(--poki-blue);
    `}
`;

const QAModuleStatusIndicator = styled.div`
    width: 100%;
    margin: 4px 0 0;
`;

const QAModuleStepWrapper = styled.li`
    border-top: 1px solid var(--grey-7);
    margin: 0 0 16px;
    list-style: none;
`;

const QAModuleSummaryExpandIcon = styled(SVGIcon)`
    width: 7px;
    height: 12px;

    position: absolute;
    top: 24px;
    right: 8px;
    transform: translateY(-50%);
    color: var(--grey-5);

    ${QAModuleWrapper}[open] & {
        transform: translateY(-50%) rotate(-90deg);
    }
`;

const QAModuleSteps = styled.ul`
    margin: 8px 0 0;
    padding: 0;
`;

export default function QAModule({
    moduleIcon, moduleID, moduleName, steps, isOpen, handleModuleOpen,
}) {
    // if no steps, then the module is not developed yet and we render a placeholder
    const hasSteps = steps.length > 0;

    const completedSteps = useMemo(() => steps
        .filter(({ stepStatus }) => stepStatus !== STEP_STATUS.NOT_STARTED), [steps]);

    const passedSteps = useMemo(() => steps
        .filter(({ stepStatus }) => stepStatus === STEP_STATUS.PASSED), [steps]);

    const failedSteps = useMemo(() => steps
        .filter(({ stepStatus }) => stepStatus === STEP_STATUS.FAILED), [steps]);

    const hasCompletedSteps = completedSteps.length > 0;
    const hasFailedSteps = failedSteps.length > 0;
    const allStepsFullyCompleted = completedSteps.length === steps.length;

    const statusText = `${completedSteps.length} out of ${steps.length}`;
    const errorNumberText = `${failedSteps.length} condition(s) not met`;

    // a qa module is disabled if it has no steps 🤔
    if (!hasSteps) {
        return (
            <QAModuleWrapper $isDisabled>
                <QAModuleSummary>
                    <QAModuleIconWrapper><QAModuleSVGIcon icon={moduleIcon} /></QAModuleIconWrapper>
                    <QAModuleSummaryTextWrap>
                        <QAModuleTitle>{moduleName}</QAModuleTitle>
                    </QAModuleSummaryTextWrap>
                </QAModuleSummary>
            </QAModuleWrapper>
        );
    }

    return (
        <QAModuleWrapper
            $hasCompletedSteps={hasCompletedSteps}
            open={isOpen}
        >
            <QAModuleSummary onClick={handleModuleOpen}>
                <QAModuleIconWrapper><QAModuleSVGIcon icon={moduleIcon} /></QAModuleIconWrapper>
                <QAModuleStatus>
                    <QAModuleTitle>{moduleName}</QAModuleTitle>
                    <QAModuleStatusText
                        $hasCompletedSteps={hasCompletedSteps}
                        $allStepsFullyCompleted={allStepsFullyCompleted}
                    >
                        {statusText}
                    </QAModuleStatusText>
                    <QAModuleStatusIndicator>
                        <IndicatorBar
                            stepCounter={steps.length}
                            passedSteps={passedSteps.length}
                            failedSteps={failedSteps.length}
                        />
                    </QAModuleStatusIndicator>
                </QAModuleStatus>
                <QAModuleSummaryExpandIcon icon="chevronleft" />
            </QAModuleSummary>
            {
                hasFailedSteps
                && (
                    <QAModuleErrorNum>
                        {errorNumberText}
                    </QAModuleErrorNum>
                )
            }
            <QAModuleSteps>
                {steps.map(({
                    stepKey, stepName, stepDescription, stepErrorMessage, stepStatus, stepRequiredImplementation,
                }) => (
                    <QAModuleStepWrapper key={stepName}>
                        <QAModuleStep
                            moduleID={moduleID}
                            stepKey={stepKey}
                            stepName={stepName}
                            stepDescription={stepDescription}
                            stepErrorMessage={stepErrorMessage}
                            stepStatus={stepStatus}
                            stepRequiredImplementation={stepRequiredImplementation}
                        />
                    </QAModuleStepWrapper>
                ))}
            </QAModuleSteps>
        </QAModuleWrapper>
    );
}

const stepPropType = PropTypes.shape({
    stepName: PropTypes.string.isRequired,
    stepDescription: PropTypes.string,
    stepStatus: PropTypes.oneOf(Object.values(STEP_STATUS)).isRequired,
    isManualStep: PropTypes.bool,
});

QAModule.propTypes = {
    moduleID: PropTypes.string.isRequired,
    moduleName: PropTypes.string.isRequired,
    moduleIcon: PropTypes.string.isRequired,
    steps: PropTypes.arrayOf(stepPropType).isRequired,
    isOpen: PropTypes.bool,
    handleModuleOpen: PropTypes.func.isRequired,
};

QAModule.defaultProps = {
    isOpen: false,
};
