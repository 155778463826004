import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PassToggle from 'src/components/ui/PassToggle';
import SVGIcon from 'src/components/ui/SVGIcon';
import Tooltip from 'src/components/ui/Tooltip';

import { selectStepsDisabledStatus, syncModules, updateStepStatus } from 'src/slices/qamodules.slice';

import { STEP_STATUS } from 'src/assets/qamodules/default';
import { selectGame } from 'src/slices/game.slice';

const QAModuleStepWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 16px 0;
    gap: 12px;

    em {
        /* this is used to highlight sdk event names */
        font-style: normal;
        background-color: var(--data-green-1);
        padding: 2px 6px;
        border-radius: 4px;

        span {
            font-size: 10px;
        }

        &.loading-event {
            background-color: var(--data-blue-1);
        }

        &.gameplay-event {
            background-color: var(--data-green-1);
        }

        &.break-event {
            background-color: var(--data-orange-1);
        }
    }
`;

const QAModuleStepSummaryTitle = styled.span`
    width: 100%;

    ${({ $status }) => $status === STEP_STATUS.FAILED && `
        color: var(--rose-1);
        font-weight: 600;
    `}

`;

const QAModuleStepTooltipIcon = styled(SVGIcon)`
    display: inline-block;
    vertical-align: middle;

    width: 24px;
    height: 24px;
    color: var(--grey-5);

    ${({ $state }) => $state === 'error' && `
        color: var(--rose-1);
    `}
`;

export default function QAModuleStep({
    moduleID, stepName, stepKey, stepDescription, stepErrorMessage, stepStatus,
}) {
    const dispatch = useDispatch();
    const stepsDisabledStatus = useSelector(selectStepsDisabledStatus);
    const { id: gameID } = useSelector(selectGame);

    const stepDisabled = stepsDisabledStatus[stepKey] || false;

    function callbackPassToggle(isPassed) {
        if (isPassed === null) {
            dispatch(updateStepStatus({
                moduleID,
                stepKey,
                stepStatus: STEP_STATUS.NOT_STARTED,
            }));
        } else {
            dispatch(updateStepStatus({
                moduleID,
                stepKey,
                stepStatus: isPassed ? STEP_STATUS.PASSED : STEP_STATUS.FAILED,
            }));
        }

        dispatch(syncModules({ gameID }));
    }

    const isStepPassed = stepStatus === STEP_STATUS.PASSED;
    const hasDescription = stepDescription && !stepDisabled;
    const hasError = stepErrorMessage && stepDisabled && !isStepPassed;

    return (
        <QAModuleStepWrapper>
            <QAModuleStepSummaryTitle $status={stepStatus} dangerouslySetInnerHTML={{ __html: stepName }} />
            {hasDescription && (
                <Tooltip content={stepDescription}>
                    <span>
                        <QAModuleStepTooltipIcon icon="question" />
                    </span>
                </Tooltip>
            )}
            {hasError && (
                <Tooltip content={stepErrorMessage}>
                    <span>
                        <QAModuleStepTooltipIcon icon="exclamation" $state="error" />
                    </span>
                </Tooltip>
            )}
            <PassToggle
                status={stepStatus}
                disabled={stepDisabled}
                callbackPassed={() => callbackPassToggle(true)}
                callbackFailed={() => callbackPassToggle(false)}
                callbackClear={() => callbackPassToggle(null)}
            />
        </QAModuleStepWrapper>
    );
}

QAModuleStep.propTypes = {
    moduleID: PropTypes.string.isRequired,
    stepKey: PropTypes.string.isRequired,
    stepName: PropTypes.string.isRequired,
    stepDescription: PropTypes.string,
    stepErrorMessage: PropTypes.string,
    stepStatus: PropTypes.oneOf(Object.values(STEP_STATUS)).isRequired,
};

QAModuleStep.defaultProps = {
    stepErrorMessage: '',
    stepDescription: '',
};
