import { createSlice } from '@reduxjs/toolkit';
import isMobileOrTablet from 'src/utils/mobileDetection';
import { MOBILE_DEVICES } from 'src/vars';

const initialState = {
    isMobileOrTablet,
    scalingTestDevice: null,
    currentDeviceID: null,
    mobileDevices: MOBILE_DEVICES,
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setInitialState: (state) => {
            state.isMobileOrTablet = initialState.isMobileOrTablet;
        },
        setScalingTestDevice: (state, action) => {
            state.scalingTestDevice = action.payload.device;
        },
        setCurrentDeviceID: (state, action) => {
            state.currentDeviceID = action.payload.id;
        },
        setMobileDevices: (state, action) => {
            state.mobileDevices = action.payload.devices;
        },
    },
});

export const { setScalingTestDevice, setMobileDevices, setCurrentDeviceID } = appSlice.actions;

export const selectIsMobileOrTablet = (state) => state.app.isMobileOrTablet;
export const selectScalingTestDevice = (state) => state.app.scalingTestDevice;
export const selectCurrentDeviceID = (state) => state.app.currentDeviceID;
export const selectMobileDevices = (state) => state.app.mobileDevices;

export const selectApplicationTitle = (state) => {
    const { entity: { gameTitle, originalFilename } } = state.game;
    const gameTitleOrFilename = gameTitle || originalFilename;

    return gameTitleOrFilename ? `Inspector - ${gameTitleOrFilename}` : 'Inspector - Poki for Developers';
};

export default appSlice.reducer;
