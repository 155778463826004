import { combineEpics } from 'redux-observable';

import { inspectorSetShowModulesEpic, inspectorDeviceSwitchEpic, setMobileAdsOpportunityEpic } from 'src/epics/inspectorEpic';
import { gameMessageEpic, gameMessageRemoteEpic, gameWarningsEpic } from 'src/epics/gameEpic';
import qaModulesEpics from 'src/epics/qaModulesEpic';
import { gameplayStartEpic, gameplayStopEpic, sdkInitializedEpic } from 'src/epics/gameEventsEpic';

const rootEpic = combineEpics(
    inspectorSetShowModulesEpic,
    inspectorDeviceSwitchEpic,

    gameMessageEpic,
    gameMessageRemoteEpic,
    gameWarningsEpic,

    setMobileAdsOpportunityEpic,

    sdkInitializedEpic,
    gameplayStopEpic,
    gameplayStartEpic,

    // Spreading the qaModulesEpics object into the combineEpics will include all the epics from the object
    ...Object.values(qaModulesEpics),
);

export default rootEpic;
