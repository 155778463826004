import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectNotifications } from 'src/slices/notification.slice';

import Notification from 'src/components/ui/Notification';

const NotificationsWrapper = styled.div`
    position: fixed;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
`;

function Notifications() {
    const notifications = useSelector(selectNotifications);

    return (
        <NotificationsWrapper>
            {notifications.map((notification) => (
                <Notification key={notification.id} notification={notification} />
            ))}
        </NotificationsWrapper>
    );
}

export default Notifications;
