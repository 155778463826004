import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { clearUploadHistory, selectUploadHistory } from 'src/slices/inspector.slice';
import { getGameStats } from 'src/slices/game.slice';

import Button from 'src/components/ui/Button';
import SVGIcon from 'src/components/ui/SVGIcon';
import { clearPersistedState } from 'src/utils/persist';

const RecentUploadsModuleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const RecentUploadsModuleHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin: 0 0 8px;
`;

const RecentUploadsModuleTitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const RecentUploadsModuleTitle = styled.h2`
    font-size: 24px;
    font-weight: 700;
    margin: 0;
`;

const RecentUploadsModuleSubtitle = styled.h3`
    font-size: 16px;
    font-weight: 400;
    color: var(--grey-3);
    margin: 0 0 26px;
`;

const RecentUploadsModuleIcon = styled(SVGIcon)`
    width: 24px;
    height: 24px;
    color: var(--grey-5);
`;

const RecentUploadsModuleContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 32px;
`;

const RecentUpload = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    gap: 16px;
    padding: 16px;

    background: var(--grey-8);
    border-radius: 8px;
`;

const RecentUploadTitle = styled.h3`
    font-size: 16px;
    font-weight: 700;
    margin: 0;
`;

const RecentUploadButton = styled(Button)`
    background: var(--grey-8);
`;

const RecentUploadsModuleLoadMore = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const RecentUploadsModuleButton = styled(Button)`
    display: inline-block;
    vertical-align: middle;
    padding: 0;

    color: var(--poki-blue);
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;

    cursor: pointer;
    text-wrap: nowrap;
    border: none;

    &:hover {
        background-color: transparent;
        color: var(--poki-blue);
        text-decoration: underline;
    }

    &[disabled] {
        background-color: transparent;
        color: var(--grey-5);
        cursor: not-allowed;
    }
`;

const INITIAL_UPLOAD_DISPLAY_COUNT = 5;

function RecentUploadsModule() {
    const dispatch = useDispatch();

    const [uploadCounter, setUploadCounter] = useState(INITIAL_UPLOAD_DISPLAY_COUNT);

    const uploadHistory = useSelector(selectUploadHistory);

    const sortedAndSlicedUploadHistory = useMemo(() => (
        Object.keys(uploadHistory).sort((a, b) => uploadHistory[b].timestamp - uploadHistory[a].timestamp).slice(0, uploadCounter)
    ), [uploadHistory, uploadCounter]);

    const uploadHistoryLength = Object.keys(uploadHistory).length;

    const handleRecentUpload = (key) => {
        dispatch(getGameStats({ detailsID: key }));
    };

    const handleUploadCounter = () => {
        setUploadCounter(uploadCounter + 5);
    };

    const handleClearAllHistory = () => {
        clearPersistedState({ id: 'poki_upload_history' });
        dispatch(clearUploadHistory());
    };

    return (
        <RecentUploadsModuleWrapper>
            <RecentUploadsModuleHeader>
                <RecentUploadsModuleTitleContainer>
                    <RecentUploadsModuleTitle>Recent uploads</RecentUploadsModuleTitle>
                    <RecentUploadsModuleIcon icon="clock" />
                </RecentUploadsModuleTitleContainer>
                <RecentUploadsModuleButton onClick={handleClearAllHistory}>Clear All</RecentUploadsModuleButton>
            </RecentUploadsModuleHeader>
            <RecentUploadsModuleSubtitle>Start from one of your previous uploads</RecentUploadsModuleSubtitle>
            <RecentUploadsModuleContent>
                {sortedAndSlicedUploadHistory.map((key) => {
                    const { gameTitle, originalFilename, timestamp } = uploadHistory[key];

                    return (
                        <RecentUpload key={key}>
                            <div>
                                <RecentUploadTitle>{gameTitle || originalFilename}</RecentUploadTitle>
                                {new Date(timestamp).toLocaleString()}
                            </div>
                            <RecentUploadButton onClick={() => handleRecentUpload(key)}>Open</RecentUploadButton>
                        </RecentUpload>
                    );
                })}
            </RecentUploadsModuleContent>
            {uploadHistoryLength > 5 && uploadCounter <= uploadHistoryLength && (
                <RecentUploadsModuleLoadMore>
                    <RecentUploadsModuleButton
                        onClick={handleUploadCounter}
                    >
                        Load More
                    </RecentUploadsModuleButton>
                </RecentUploadsModuleLoadMore>
            )}
        </RecentUploadsModuleWrapper>
    );
}

export default RecentUploadsModule;
