import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getPersistedState } from 'src/utils/persist';

import defaultModules from 'src/assets/qamodules/default';
import { STEPS_DISABLED_STATUS } from 'src/vars';

const { stepsDisabledStatus, currentOpenModule } = getPersistedState({});

export const selectQAModules = (state) => state?.qaModules?.modules;
export const selectCurrentOpenModule = (state) => state?.qaModules?.currentOpenModule;
export const selectStepsDisabledStatus = (state) => state?.qaModules?.stepsDisabledStatus;

export const syncModules = createAsyncThunk(
    'qaModules/syncModules',
    async ({ gameID }, { getState }) => {
        const state = getState();
        const { qaModules: { modules } } = state;

        const moduleKeys = modules.reduce((acc, module) => {
            module.steps.forEach(({ stepKey, stepStatus }) => {
                acc[stepKey] = stepStatus;
            });

            return acc;
        }, {});
        await axios.post(`https://inspector-api.poki.io/v0/checklist/${gameID}`, moduleKeys, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .catch((error) => {
                console.error('Error while syncing modules', error);
            });
    },
);

const initialState = {
    modules: defaultModules,
    stepsDisabledStatus: stepsDisabledStatus || STEPS_DISABLED_STATUS,
    currentOpenModule: currentOpenModule || 'sdk-basics',
};

const qaModulesSlice = createSlice({
    name: 'qaModules',
    initialState,
    reducers: {
        setInitialState: (state, action) => {
            state.stepsDisabledStatus = action.payload.stepsDisabledStatus || initialState.stepsDisabledStatus;
            state.currentOpenModule = action.payload.currentOpenModule || initialState.currentOpenModule;
        },
        updateModules: (state, action) => {
            state.modules = action.payload;
        },
        updateCurrentOpenModule: (state, action) => {
            state.currentOpenModule = action.payload.currentOpenModule;
        },
        setStepsDisabledStatus: (state, action) => {
            state.stepsDisabledStatus = action.payload.stepsDisabledStatus;
        },
        updateStepsWithChecklist: (state, action) => {
            const { checklist } = action.payload;

            state.modules.forEach((module) => {
                module.steps.forEach((step) => {
                    const { stepKey, stepStatus } = step;

                    step.stepStatus = checklist[stepKey] || stepStatus;
                });
            });
        },
        updateStepStatus: (state, action) => {
            const { moduleID, stepKey, stepStatus } = action.payload;

            const moduleIndex = state.modules.findIndex((module) => module.moduleID === moduleID);
            const stepIndex = state.modules[moduleIndex].steps.findIndex((step) => step.stepKey === stepKey);
            state.modules[moduleIndex].steps[stepIndex].stepStatus = stepStatus;
        },
    },
});

export const {
    setInitialState,
    updateModules,
    updateStepStatus,
    updateStepsWithChecklist,
    updateCurrentOpenModule,
    setStepsDisabledStatus,
} = qaModulesSlice.actions;

export default qaModulesSlice.reducer;
