import { ofType } from 'redux-observable';
import { EMPTY, Subject, filter, map, switchMap, take, takeUntil } from 'rxjs';
import { STEP_STATUS } from 'src/assets/qamodules/default';

import { selectGameEvents, setGameEvent } from 'src/slices/gameEvents.slice';
import { selectIsRemoteTestingEnv } from 'src/slices/inspector.slice';
import { setStepsDisabledStatus, syncModules, updateStepStatus } from 'src/slices/qamodules.slice';

import { SDK_EVENTS } from 'src/vars';

// Checks the sequence of gameplay events to determine if the first gameplay stop event occurs before a gameplay start event.
// If so the step is disabled.
function checkFirstGameplayStop(gameEvents) {
    const gameplayEvents = gameEvents.filter(({ event }) => event === SDK_EVENTS.GAMEPLAY_START || event === SDK_EVENTS.GAMEPLAY_STOP);

    const gameplayStartIndex = gameplayEvents.findIndex((event) => event.event === SDK_EVENTS.GAMEPLAY_START);
    const gameplayStopIndex = gameplayEvents.findIndex((event) => event.event === SDK_EVENTS.GAMEPLAY_STOP);

    if (gameplayStartIndex === -1 && gameplayStopIndex === 0) {
        return false;
    }

    return true;
}

export function sdkInitializedEpic(action$, state$) {
    const isRemoteEnv = selectIsRemoteTestingEnv(state$.value);

    if (isRemoteEnv) {
        return EMPTY;
    }

    return action$.pipe(
        ofType(setGameEvent.type),
        filter((action) => action.payload.event === SDK_EVENTS.SDK_STATUS_INITIALIZED),
        take(1),
        switchMap(() => [
            updateStepStatus({ moduleID: 'sdk-basics', stepKey: 'sdk-basics-init-sdk', stepStatus: STEP_STATUS.PASSED }),
            setStepsDisabledStatus({ stepsDisabledStatus: { ...state$.value.qaModules.stepsDisabledStatus, 'sdk-basics-init-sdk': true } }),
            syncModules({ gameID: state$.value.game.gameID }),
        ]),
    );
}

export function gameplayStopEpic(action$, state$) {
    return action$.pipe(
        ofType(setGameEvent.type),
        filter((action) => action.payload.event === SDK_EVENTS.GAMEPLAY_STOP),
        take(1),
        map(() => {
            const gameEvents = selectGameEvents(state$.value);
            const stopOrderValid = checkFirstGameplayStop(gameEvents);

            let stepDisabled = false;

            if (!stopOrderValid) {
                stepDisabled = true;
            }

            return setStepsDisabledStatus({
                stepsDisabledStatus: { ...state$.value.qaModules.stepsDisabledStatus, 'sdk-basics-gameplay-stop': stepDisabled },
            });
        }),
    );
}

export function gameplayStartEpic(action$, state$) {
    const stop$ = new Subject();
    const epic$ = action$.pipe(
        ofType(setGameEvent.type),
        filter((action) => action.payload.event === SDK_EVENTS.GAMEPLAY_START),
        map((action) => {
            const { interaction } = action.payload.data;

            let stepDisabled = false;

            // If there's no interaction we disable the module and stop the epic.
            if (!interaction) {
                stepDisabled = true;
                stop$.next();
            }

            return setStepsDisabledStatus({
                stepsDisabledStatus: { ...state$.value.qaModules.stepsDisabledStatus, 'sdk-basics-gameplay-start': stepDisabled },
            });
        }),
        takeUntil(stop$),
    );

    return epic$;
}
