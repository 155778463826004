import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

import { closeNotification } from 'src/slices/notification.slice';

import SVGIcon from 'src/components/ui/SVGIcon';

const fadeInKeyframes = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOutKeyframes = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const NotificationWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    min-width: 200px;
    padding: 12px;
    border-radius: 8px;

    background-color: var(--green-7);
    color: #000;
    font-weight: 700;
    z-index: 1000;

    animation: ${({ $animationState }) => ($animationState === 'fadeIn' ? fadeInKeyframes : fadeOutKeyframes)} 0.2s ease-in-out;

    ${({ $type }) => $type === 'error' && `
        background-color: var(--rose-7);
    `}
`;

const NotificationMessage = styled.p`
    margin: 0;
    justify-self: center;
`;

const NotificationStatus = styled.div`
    color: #000;
`;

const NotificationCloseButton = styled.button`
    background: none;
    padding: 0;
    border: none;
    cursor: pointer;
`;

const NotificationCloseIcon = styled(SVGIcon)`
    width: 12px;
    height: 12px;
    color: var(--green-2);

    ${({ $type }) => $type === 'error' && `
        color: var(--rose-2);
    `}
`;

const NotificationClose = styled.div`
    display: flex;
    align-items: center;
`;

const NotificationDivider = styled.div`
    width: 1px;
    height: 20px;
    background-color: var(--green-2);
    margin: 0 10px;

    ${({ $type }) => $type === 'error' && `
        background-color: var(--rose-2);
    `}
`;

function Notification({ notification }) {
    const {
        id,
        type,
        status,
        message,
    } = notification;

    const dispatch = useDispatch();
    const timeoutRef = useRef(null);

    const [animationState, setAnimationState] = useState('fadeIn');

    // clear each notification after 3 seconds
    useEffect(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            setAnimationState('fadeOut');

            setTimeout(() => {
                dispatch(closeNotification(id));
            }, 200);
        }, 3000);

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [id, dispatch]);

    return (
        <NotificationWrapper $type={type} $animationState={animationState}>
            {status && (
                <NotificationStatus>{`${status}`}</NotificationStatus>
            )}
            <NotificationMessage>{message}</NotificationMessage>
            <NotificationClose>
                <NotificationDivider $type={type} />
                <NotificationCloseButton onClick={() => dispatch(closeNotification(id))}>
                    <NotificationCloseIcon icon="cross" $type={type} />
                </NotificationCloseButton>
            </NotificationClose>
        </NotificationWrapper>
    );
}

Notification.propTypes = {
    notification: PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        status: PropTypes.number,
        message: PropTypes.string.isRequired,
    }).isRequired,
};

export default Notification;
