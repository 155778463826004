import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
    setInitialState as setUploadInitialState,
    selectUploadProgress,
    selectUploadSuccess,
    selectUploadError,
} from 'src/slices/upload.slice';

import Button from 'src/components/ui/Button';

const UploadProgressWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 32px;
    height: 100%;

    border-radius: 8px;
    border: 2px solid var(--poki-blue);

    ${(props) => props.isUploadSuccess && `
        border: none;
        background-color: var(--grey-8);
    `}
`;

const UploadProgressCircle = styled.div`
    position: relative;
    width: 104px;
    height: 104px;
    margin: 0 auto;

    border-radius: 50%;
    background: conic-gradient(var(--poki-blue) var(--gradientDegree, 0deg), var(--grey-5) 0deg);

    font-size: 28px;
    color: var(--poki-blue);

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 98px;
        height: 98px;
        border-radius: 50%;
        background: #fff;
    }
`;

const UploadProgressValue = styled.strong`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const UploadProgressSpinnerContainer = styled.div`
    width: 104px;
    height: 104px;
    margin: 0 auto;
    padding: 16px;
`;

const UploadProgressSpinner = styled.video`
    width: 100%;
    height: 100%;
`;

const UploadProgressStatus = styled.div`
    width: 100%;
    text-align: center;
`;

const UploadProgressStatusMessage = styled.span`
    text-align: center;
    font-size: 16px;
    font-weight: 700;

    color: ${(props) => (props.hasError ? 'var(--rose-1)' : 'var(--grey-3)')};
`;

const UploadProgressStatusActions = styled.div`
    display: flex;
    justify-content: center;

    gap: 16px;
    margin: 22px 0 0;
`;

const UPLOADING_JOKES = [
    'Wheeee, uploading!',
    'Making progress...',
    'Beep boop beep!',
    'Just a moment longer...',
    'Almost there!',
];

function getUploadMessage({ uploadProgress, uploadSuccess, uploadError }) {
    if (uploadSuccess) {
        return 'Uploaded!';
    }

    if (uploadError) {
        return `${uploadError}, try again!`;
    }

    if (uploadProgress === 100) {
        return 'Processing...';
    }

    if (uploadProgress > 90) {
        return UPLOADING_JOKES[4];
    }

    if (uploadProgress > 70) {
        return UPLOADING_JOKES[3];
    }

    if (uploadProgress > 50) {
        return UPLOADING_JOKES[2];
    }

    if (uploadProgress > 30) {
        return UPLOADING_JOKES[1];
    }

    if (uploadProgress > 10) {
        return UPLOADING_JOKES[0];
    }

    return 'Uploading...';
}

function progressToDegrees(progress) {
    const degrees = progress * 3.6;
    return `${degrees}deg`; // 100% = 360deg
}

export default function UploadProgress({ onAbort }) {
    const dispatch = useDispatch();

    const uploadProgress = useSelector(selectUploadProgress);
    const uploadSuccess = useSelector(selectUploadSuccess);
    const uploadError = useSelector(selectUploadError);

    // reset the game state if a user decides to cancel current upload or upload an another game
    const handleGameStateReset = () => {
        onAbort(); // cancel the current upload
        dispatch(setUploadInitialState());
    };

    // 100% is the last progress state before processing then we don't know the exact progress
    // so we will show a loading state until the upload is successful or failed
    const isProcessing = uploadProgress === 100 && !uploadSuccess && !uploadError;

    return (
        <UploadProgressWrapper isUploadSuccess={uploadSuccess}>
            {isProcessing && (
                <UploadProgressSpinnerContainer>
                    <UploadProgressSpinner
                        src="/assets/spinner.mp4"
                        autoPlay
                        loop
                        muted
                    />
                </UploadProgressSpinnerContainer>
            )}
            {!isProcessing && (
                <UploadProgressCircle style={{ '--gradientDegree': progressToDegrees(uploadProgress) }}>
                    <UploadProgressValue>
                        {Math.floor(uploadProgress)}
                        %
                    </UploadProgressValue>
                </UploadProgressCircle>
            )}

            <UploadProgressStatus>
                <UploadProgressStatusMessage hasError={uploadError}>
                    {getUploadMessage({ uploadProgress, uploadSuccess, uploadError })}
                </UploadProgressStatusMessage>

                {/* if we are already processing it's a bit late to cancel or do anything else */}
                {!isProcessing && (
                    <UploadProgressStatusActions>
                        <Button onClick={handleGameStateReset}>Cancel</Button>
                    </UploadProgressStatusActions>
                )}
            </UploadProgressStatus>
        </UploadProgressWrapper>
    );
}

UploadProgress.propTypes = {
    onAbort: PropTypes.func.isRequired,
};
