import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PassToggle from 'src/components/ui/PassToggle';
import SVGIcon from 'src/components/ui/SVGIcon';
import Tooltip from 'src/components/ui/Tooltip';

import { selectStepsDisabledStatus, syncModules, updateStepStatus } from 'src/slices/qamodules.slice';

import { STEP_STATUS } from 'src/assets/qamodules/default';
import { selectGame } from 'src/slices/game.slice';

const QAModuleStepWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 16px 0;
    gap: 12px;

    em {
        /* this is used to highlight sdk event names */
        font-style: normal;
        background-color: var(--data-green-1);
        padding: 2px 6px;
        border-radius: 4px;

        span {
            font-size: 10px;
        }

        &.loading-event {
            background-color: var(--data-blue-1);
        }

        &.gameplay-event {
            background-color: var(--data-green-1);
        }

        &.break-event {
            background-color: var(--data-orange-1);
        }
    }
`;

const QAModuleStepSummaryTitle = styled.span`
    width: 100%;

    ${({ $status }) => $status === STEP_STATUS.FAILED && `
        color: var(--rose-1);
        font-weight: 600;
    `}

`;

const QAModuleStepTooltipIcon = styled(SVGIcon)`
    display: inline-block;
    vertical-align: middle;

    width: 24px;
    height: 24px;
    color: var(--grey-5);

    ${({ $state }) => $state === 'error' && `
        color: var(--rose-1);
    `}
`;

export default function QAModuleStep({
    moduleID, stepName, stepKey, stepDescription, stepErrorMessage, stepStatus, stepRequiredImplementation,
}) {
    const dispatch = useDispatch();
    const stepsDisabledStatus = useSelector(selectStepsDisabledStatus);
    const { id: gameID } = useSelector(selectGame);

    let tooltipContent = null;
    let tooltipIcon = null;
    let tooltipState = null;

    const stepDisabled = stepsDisabledStatus[stepKey] || false;
    const isStepPassed = stepStatus === STEP_STATUS.PASSED;
    const hasDescription = stepDescription && !stepDisabled;
    const hasError = stepErrorMessage && stepDisabled && !isStepPassed;

    function callbackPassToggle(status) {
        dispatch(updateStepStatus({ moduleID, stepKey, stepStatus: status }));
        dispatch(syncModules({ gameID }));
    }

    if (hasDescription) {
        tooltipContent = stepDescription;
        tooltipIcon = 'question';
    } else if (hasError) {
        tooltipContent = stepErrorMessage;

        if (stepRequiredImplementation) {
            tooltipIcon = 'exclamation';
            tooltipState = 'error';
        } else {
            tooltipIcon = 'question';
        }
    }

    return (
        <QAModuleStepWrapper>
            <QAModuleStepSummaryTitle $status={stepStatus} dangerouslySetInnerHTML={{ __html: stepName }} />
            {tooltipContent && (
                <Tooltip content={tooltipContent}>
                    <span>
                        <QAModuleStepTooltipIcon icon={tooltipIcon} $state={tooltipState} />
                    </span>
                </Tooltip>
            )}
            <PassToggle
                status={stepStatus}
                disabled={stepDisabled}
                callbackPassed={() => callbackPassToggle(STEP_STATUS.PASSED)}
                callbackFailed={() => callbackPassToggle(STEP_STATUS.FAILED)}
                callbackClear={() => callbackPassToggle(STEP_STATUS.NOT_STARTED)}
            />
        </QAModuleStepWrapper>
    );
}

QAModuleStep.propTypes = {
    moduleID: PropTypes.string.isRequired,
    stepKey: PropTypes.string.isRequired,
    stepName: PropTypes.string.isRequired,
    stepDescription: PropTypes.string,
    stepErrorMessage: PropTypes.string,
    stepStatus: PropTypes.oneOf(Object.values(STEP_STATUS)).isRequired,
    stepRequiredImplementation: PropTypes.bool,
};

QAModuleStep.defaultProps = {
    stepErrorMessage: '',
    stepDescription: '',
    stepRequiredImplementation: false,
};
