const getFileFromEntry = async (entry) => (
    new Promise((resolve, reject) => {
        entry.file((file) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = () => reject(console.error('Error reading file'));
            reader.readAsArrayBuffer(file);
        });
    })
);

const readEntriesFromDirectory = async (directory) => (
    new Promise((resolve) => {
        const reader = directory.createReader();
        const entries = [];

        // Keep calling readEntries() until no more results are returned.
        const readEntries = () => {
            reader.readEntries((result) => {
                if (!result.length) {
                    resolve(entries);
                } else {
                    entries.push(...result);
                    readEntries();
                }
            });
        };
        readEntries();
    })
);

// Recursively add each file and subfolder to the zip file
const addEntryToZip = async (zip, entry, path) => {
    try {
        if (entry.isFile) {
            const file = await getFileFromEntry(entry);
            zip.file(`${path}${entry.name}`, file);
        } else if (entry.isDirectory) {
            zip.folder(`${path}${entry.name}/`);
            const entries = await readEntriesFromDirectory(entry);

            await Promise.all(entries.map((childEntry) => (
                addEntryToZip(zip, childEntry, `${path}${entry.name}/`)
            )));
        }
    } catch (error) {
        console.error(`Error adding entry to zip: ${error.message}`);
    }
};

export default addEntryToZip;
