import PropTypes from 'prop-types';
import styled from 'styled-components';

const StatWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 8px 12px;
    border-radius: 4px;
    background: var(--grey-7);

    color: var(--grey-3);
    font-size: 14px;
`;

export default function Stat({ label, value }) {
    return (
        <StatWrapper>
            <span>{label}</span>
            <strong>{value}</strong>
        </StatWrapper>
    );
}

Stat.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
};

Stat.defaultProps = {
    label: '',
    value: '',
};
