import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'tippy.js/dist/tippy.css';

// Tooltip component using tippyjs library
// Children needs to be wrapped in a span in order to work properly

const TooltipTippy = styled(Tippy)`
    padding: 10px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 4px 8px rgba(9, 30, 66, 0.07), 0px 0px 2px rgba(9, 30, 66, 0.07);
    color: var(--grey-1);

    .tippy-arrow {
        color: #fff;
    }
`;

export default function Tooltip({ content, arrow, placement, children }) {
    return (
        <TooltipTippy
            appendTo={document.body}
            arrow={arrow}
            delay={[0, 0]}
            placement={placement}
            interactive
            content={content}
        >
            <span>{children}</span>
        </TooltipTippy>
    );
}

Tooltip.propTypes = {
    content: PropTypes.string.isRequired,
    arrow: PropTypes.bool,
    placement: PropTypes.string,
    children: PropTypes.node.isRequired,
};

Tooltip.defaultProps = {
    arrow: false,
    placement: 'right',
};
