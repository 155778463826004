import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import EventLog from 'src/components/ui/EventLog';
import Button from 'src/components/ui/Button';

import { clearGameEvents, selectGameEvents } from 'src/slices/gameEvents.slice';

import { SCREEN_SIZES } from 'src/vars';

const EventLogModuleWrapper = styled.div`
    display: flex;
    container-type: inline-size;
    flex-direction: column;
    overflow-y: auto;

    height: calc(100% - 56px);

    background: #fff;
    border-radius: 8px;
    
`;

const EventLogModuleHeader = styled.div`
    display: none;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px 0 0;
    margin: 0;

    @media (min-width: ${SCREEN_SIZES.LARGE}px) {
        display: flex;
    }
`;

const EventLogModuleTitle = styled.h2`
    align-self: flex-start;
    width: 100%;

    position: sticky;
    top: 0;
    z-index: 1;
    background: #fff;

    margin: 0;
    padding: 13px 16px 12px;

    font-size: 21px;
    border-bottom: 2px solid var(--grey-8);
`;

const EventLogModuleClearButton = styled(Button)`
    padding: 0;
    color: var(--poki-blue);
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;

    cursor: pointer;
    text-wrap: nowrap;
    border: none;

    &:hover {
        background-color: transparent;
        text-decoration: underline;
        color: var(--poki-blue);
    }

    &[disabled] {
        background-color: transparent;
        color: var(--grey-5);
        cursor: not-allowed;
    }
`;

const EventLogModuleEvents = styled.ul`
    flex: 1;
    padding: 18px 8px;
    margin: 0;

    @media (min-width: ${SCREEN_SIZES.LARGE}px) {
        overflow-y: auto;
    }
`;

const EventLogModuleEvent = styled.li`
    list-style: none;
    margin: 0 0 8px;

    &:last-of-type {
        margin: 0;
    }
`;

export default function EventLogModule() {
    const dispatch = useDispatch();

    const [isHovered, setIsHovered] = useState(false);
    const lastEventRef = useRef(null);

    const gameEvents = useSelector(selectGameEvents);

    const handleClearEventLog = () => {
        dispatch(clearGameEvents());
    };

    useEffect(() => {
        // scroll to bottom of event log when new event is added
        // and user is not hovering over the event log
        if (!isHovered && lastEventRef.current) {
            lastEventRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [isHovered, gameEvents]);

    return (
        <EventLogModuleWrapper>
            <EventLogModuleHeader>
                <EventLogModuleTitle>Event Log</EventLogModuleTitle>
                <EventLogModuleClearButton onClick={handleClearEventLog}>Clear</EventLogModuleClearButton>
            </EventLogModuleHeader>
            {gameEvents.length > 0 && (
                <EventLogModuleEvents
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    {gameEvents.map((gameEvent) => {
                        const isLastEntry = gameEvents[gameEvents.length - 1] === gameEvent;
                        const ref = isLastEntry ? lastEventRef : null;
                        return (
                            <EventLogModuleEvent key={gameEvent.id} ref={ref}>
                                <EventLog
                                    timestamp={gameEvent.timestamp}
                                    eventName={gameEvent.event}
                                    eventData={gameEvent?.data}
                                />
                            </EventLogModuleEvent>
                        );
                    })}
                </EventLogModuleEvents>
            )}
        </EventLogModuleWrapper>
    );
}
