import PropTypes from 'prop-types';
import styled from 'styled-components';

import SVGIcon from 'src/components/ui/SVGIcon';
import Button from 'src/components/ui/Button';

const AccordionDetails = styled.details`
    position: relative;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 4px 8px rgba(9, 30, 66, 0.07), 0px 0px 2px rgba(9, 30, 66, 0.07);

    &[open] {
        height: 100%;
        overflow-y: scroll;
    }
`;

const AccordionSummary = styled.summary`
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: sticky;
    top: 0;
    z-index: 1;
    padding: 13px 16px 12px;

    font-size: 21px;
    font-weight: 700;
    cursor: pointer;

    ${AccordionDetails}[open] & {
        border-bottom: 2px solid var(--grey-8);
        background: #fff;
    }
`;

const AccordionActionButton = styled(Button)`
    color: var(--poki-blue);
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;

    padding: 0;
    margin-left: auto;
    margin-right: 16px;

    cursor: pointer;
    text-wrap: nowrap;
    border: none;

    &:hover {
        background-color: transparent;
        text-decoration: underline;
        color: var(--poki-blue);
    }

    &[disabled] {
        background-color: transparent;
        color: var(--grey-5);
        cursor: not-allowed;
    }
`;

const AccordionSummaryIcon = styled(SVGIcon)`
    width: 7px;
    height: 12px;
    margin-top: 12px;

    color: var(--grey-5);
    transform: translateY(-50%);
    transition: transform 0.1s ease-in-out;

    ${AccordionDetails}[open] & {
        transform: translateY(-50%) rotate(-90deg);
    }
`;

function Accordion({
    title,
    action,
    isOpen,
    handleClick,
    handleActionClick,
    children,
}) {
    return (
        <AccordionDetails open={isOpen}>
            <AccordionSummary onClick={handleClick}>
                {title}
                {action && isOpen && (
                    <AccordionActionButton onClick={handleActionClick}>{action}</AccordionActionButton>
                )}
                <AccordionSummaryIcon icon="chevronleft" />
            </AccordionSummary>
            {children}
        </AccordionDetails>
    );
}

export default Accordion;

Accordion.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    action: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    handleClick: PropTypes.func.isRequired,
    handleActionClick: PropTypes.func,
    children: PropTypes.node,
};

Accordion.defaultProps = {
    action: null,
    handleActionClick: null,
    children: null,
};
