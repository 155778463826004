import { filter, ignoreElements, map, switchMap } from 'rxjs/operators';
import { EMPTY, fromEvent, of, tap } from 'rxjs';
import { ofType } from 'redux-observable';

import { setGameEvent, setInspectorEvent } from 'src/slices/gameEvents.slice';
import { selectIsRemoteTestingEnv } from 'src/slices/inspector.slice';
import { getNetlibNetwork, selectNetlibPeers, setNetlibIsReady } from 'src/slices/netlib.slice';
import { ACCEPTED_EVENTS } from 'src/utils/sdkDefinitions';
import { NETWORK_MESSAGE_TYPE_GAME_EVENT, NETWORK_MESSAGE_TYPE_CONSOLE } from 'src/vars';
import { setWarnings } from 'src/slices/game.slice';

// list of acceptable postMessage types that can be handled by the inspector (taken from poki-sdk definitions)
export const ACCEPTABLE_MESSAGE_TYPES = ['pokiMessageEvent', 'pokiMessageInspectorEvent'];

export const gameFilteredMessage$ = fromEvent(window, 'message').pipe(
    // @ts-ignore
    filter((event) => ACCEPTABLE_MESSAGE_TYPES.includes(event?.data?.type)), // Filter out all other postMessage events
    map((event) => event),
);

// gameMessageEpic subscribes to all postMessage events and filter out all other events that are not pokiMessageEvent
// moreover this epic handles events from the netlib peers (check gameMessageRemoteEpic for remote events)
// @ts-ignore
export function gameMessageEpic(action$, state$) {
    // check if there are too many peers connected to the netlib server
    const isTooManyPeers = () => {
        const peers = selectNetlibPeers(state$.value);
        return peers.length > 1;
    };

    return gameFilteredMessage$.pipe(
        filter(() => !isTooManyPeers()), // if there are too many peers connected then skip the event
        switchMap((event) => {
            // @ts-ignore
            const { data = {} } = event;
            const { type, content } = data;

            if (type === 'pokiMessageEvent') {
                // if content.event is not a string then skip it and return an empty observable
                // also skip the events that are in the DECLINED_EVENTS array
                if (typeof content.event !== 'string' || !ACCEPTED_EVENTS.includes(content.event)) {
                    return EMPTY;
                }

                // push the event to the gameEvents slice
                const gameEvent = {
                    id: crypto.randomUUID(), // generate a random id for the event
                    event: content.event,
                    data: content.data,
                    timestamp: Date.now(),
                };

                return of(setGameEvent(gameEvent));
            }

            if (type === 'pokiMessageInspectorEvent') {
                const inspectorEvent = {
                    event: content.event,
                    data: content.data,
                    timestamp: Date.now(),
                };

                return of(setInspectorEvent(inspectorEvent));
            }

            return EMPTY;
        }),
    );
}

// gameMessageRemoteEpic is only used when the inspector is in remote testing mode
// it will connect to the netlib server and send all game events to the remote inspector
export function gameMessageRemoteEpic(action$, state$) {
    const isRemoteEnv = selectIsRemoteTestingEnv(state$.value);

    if (!isRemoteEnv) {
        return EMPTY;
    }

    let network;

    // netlib.slice will let us know when the netlib remote instance is ready
    const netlibRemoteReady$ = action$.pipe(
        ofType(setNetlibIsReady.type),
        filter((action) => action.payload),
        tap(() => {
            network = getNetlibNetwork(); // get the netlib network instance (should be ready by now)
        }),
    );

    // after the netlib remote instance is ready then start sending game events to the host inspector
    return netlibRemoteReady$.pipe(
        switchMap(() => gameFilteredMessage$),
        tap((event) => {
            const { data } = event;
            const { type, content } = data;

            if (type === 'pokiMessageEvent') {
                if (typeof content.event === 'string' && ACCEPTED_EVENTS.includes(content.event)) {
                    // match the event to the gameEvents slice
                    const gameEvent = {
                        event: content.event,
                        data: content.data,
                        timestamp: Date.now(),
                    };

                    network.broadcast('reliable', JSON.stringify({
                        type: NETWORK_MESSAGE_TYPE_GAME_EVENT,
                        content: gameEvent,
                    }));
                }
            } else if (type === 'pokiMessageInspectorEvent') {
                if (content.event === 'console') {
                    network.broadcast('reliable', JSON.stringify({
                        type: NETWORK_MESSAGE_TYPE_CONSOLE,
                        data: content.data,
                    }));
                }
            }
        }),
        ignoreElements(),
    );
}

// @ts-ignore
export function gameWarningsEpic(action$) {
    return action$.pipe(
        ofType(setInspectorEvent.type),
        filter((action) => action.payload.event === 'bad-behavior'),
        map(({ payload }) => {
            const { data } = payload;

            return setWarnings({
                key: 'bad-behavior',
                items: [data.behavior],
            });
        }),
    );
}
